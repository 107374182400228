import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUsers, faGlobe } from '@fortawesome/free-solid-svg-icons';

function EssonovaBanner() {
    return (
        <div className="essonova-banner">
            <div className="section-title">
                <div className="section-tag fw-bold">Unlock Your Potential</div>
                <h3 className='gradient__text'>Your Journey Starts Here.</h3>
            </div>
            <ul className="banner-list">
                <li className="banner-item">
                    <span className="icon">
                        <FontAwesomeIcon icon={faClock} />
                    </span>
                    <div>
                        <h3>More Than a Decade</h3>
                        <p>Excellence in coding bootcamps</p>
                        {/* <p>Empowering your tech dreams</p> */}
                    </div>
                </li>
                <li className="banner-item">
                    <span className="icon">
                        <FontAwesomeIcon icon={faUsers} />
                    </span>
                    <div>
                        <h3>Join Our Graduates</h3>
                        <p>Worldwide network of successful alumni</p>
                        {/* <p>Crafting careers since 20XX</p> */}
                    </div>
                </li>
                <li className="banner-item">
                    <span className="icon">
                        <FontAwesomeIcon icon={faGlobe} />
                    </span>
                    <div>
                        <h3>Global Reach and Opportunities</h3>
                        {/* <p>Present in 30+ countries, expanding opportunities</p> */}
                        <p>Bridging the digital world</p>
                    </div>
                </li>
                
               
            </ul>
        </div>
    );
}

export default EssonovaBanner;
