import React, { useState } from 'react';
import '../../assests/css/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan } from '@fortawesome/free-solid-svg-icons';

const Faq = ({ data }) => {
    // State to manage the open/closed state of each question
    const [questionState, setQuestionState] = useState({});

    // Function to toggle the open/closed state of a question
    const toggleQuestion = (question) => {
        setQuestionState((prevState) => ({
            ...prevState,
            [question]: !prevState[question],
        }));
    };

    return (
        <section id="faqs">
            <div className="faq-container">
                <div className="section-title">
                    <div className="section-tag fw-bold">FAQs</div>
                    <h3 className='gradient__text'>Training Program for Job Switching and Skill Improvement</h3>
                </div>
                {/* <div className='subtitle mb-3'>
                    Explore FAQs for a seamless start. Our E-NeuraTechLab-powered software training primes you for real-world tech projects, offering top-notch skills and guidance
                </div> */}
                <div className="row">
                    <div className="col-md-6">
                        {/* Left Side */}
                        <div className="faq-accordion" id="accordionExampleLeft">
                            {/* FAQ 1 */}
                            {data.faq_data_left.map((item, index) => (
                                <div className={`faq-item ${questionState[item.collapsevalue] ? 'active' : ''}`} key={item.collapsevalue}>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" style={{display:'flex'}}>
                                            <button
                                                className={`accordion-button ${questionState[item.collapsevalue] ? 'expanded' : ''}`}
                                                style={{ flexGrow: 9 }}
                                                onClick={() => toggleQuestion(item.collapsevalue)}
                                            >
                                                <span className="accordion-text">{item.question}</span>
                                                <span style={{ flexGrow: 1,width:25 }}
                                                    className="accordion-icon"><FontAwesomeIcon icon={faLessThan} rotation={90} /></span>
                                            </button>
                                        </h2>
                                        <div className={`accordion-collapse ${questionState[item.collapsevalue] ? 'show' : ''}`}>
                                            <div className="accordion-body">
                                                <p>{item.explanation}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-6">
                        {/* Right Side */}
                        <div className="faq-accordion" id="accordionExampleRight">
                            {/* FAQ 5 */}
                            {data.faq_data_right.map((item, index) => (
                                <div className={`faq-item ${questionState[item.collapsevalue] ? 'active' : ''}`} key={item.collapsevalue}>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className={`accordion-button ${questionState[item.collapsevalue] ? 'expanded' : ''}`}
                                                onClick={() => toggleQuestion(item.collapsevalue)}
                                            >
                                                <span className="accordion-text">{item.question}</span>
                                                <span style={{ width: 17 }} className="accordion-icon"><FontAwesomeIcon icon={faLessThan} rotation={90} /></span>
                                            </button>
                                        </h2>
                                        <div className={`accordion-collapse ${questionState[item.collapsevalue] ? 'show' : ''}`}>
                                            <div className="accordion-body">
                                                <p>{item.explanation}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
