import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../assests/css/style.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import {Benifitsdata} from '../../context/context'


function Benifits() {
  return (
    <section className='block services-block'>
      <Container fluid>
      <div className="section-tag fw-bold text-center">Benifits</div>
        <div className='title-holder'>
          <h3 className='gradient__text'>Everything you need to succeed</h3>
        </div>
        <div className='subtitle mb-3'>
          Get the best blend of online learning, in-class support, and affordability to make it easy to start today and finish strong.
        </div>
        <Row>
          {Benifitsdata.map((Benifits) => {
            return (
              <Col sm={4} className='holder' key={Benifits.id}>
                <div className='icon'>{Benifits.icon}</div>
                <h5>{Benifits.title}</h5>
                <p>{Benifits.Description}</p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default Benifits;
