import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { StateContext } from '../../components/StateManager/StateManager';
import { RingLoader } from 'react-spinners';


const ContactUs = () => {
  window.scrollTo(0, 0);

  const [requestsuccess, setRequestSuccess] = useState(null);
  const [requestfail, setRequestFail] = useState(null);

  const [loading, setLoading] = useState(false);


  // eslint-disable-next-line no-unused-vars
  const statemanager = useContext(StateContext)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    mobile: '',
    message: '',
    consent: false,
  });

  const [formErrors, setFormErrors] = useState({
    nameError: '',
    emailError: '',
    subjectError: '',
    mobileError: '',
    messageError: '',
    consentError: '',
  });

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // For checkbox inputs, use checked value, otherwise, use value
    const inputValue = type === 'checkbox' ? checked : value;

    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const validateForm = () => {
    const errors = {};

    if (formData.name.trim() === '') {
      errors.nameError = 'Name cannot be empty*';
    }

    if (formData.email.trim() === '') {
      errors.emailError = 'Email cannot be empty*';
    } else if (!validateEmail(formData.email)) {
      errors.emailError = 'Invalid email format';
    }

    if (formData.subject.trim() === '') {
      errors.subjectError = 'Subject cannot be empty*';
    }

    if (formData.mobile.trim() === '') {
      errors.mobileError = 'Mobile cannot be empty*';
    }

    if (formData.message.trim() === '') {
      errors.messageError = 'Message cannot be empty*';
    }

    if (!formData.consent) {
      errors.consentError = 'You must consent to continue*';
    }

    setFormErrors(errors);

    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      sendmessage();
    } else {
      //console.log('Form validation failed.');
    }
  };

  const sendmessage = () => {
    const data = {
      name: formData.name,
      email: formData.email,
      subject: formData.subject,
      phone: formData.mobile,
      message: formData.message,
    };
    setLoading(true); //Loaser State
    axios.post(`${process.env.REACT_APP_API_URL}/Contact`, data)
      .then((response) => {
        setLoading(false); //Loaser State
        setRequestFail(null);
        setRequestSuccess("Message Sent Successfully");
      })
      .catch((error) => {
        setLoading(false); //Loaser State
        setRequestFail("Technical Issue Message not Sent");
        setRequestSuccess(null);

        console.error(error);
      });
  }

  useEffect((() => {
    setRequestFail(null);
    setRequestSuccess(null);
  }), []);


  return (
    <>
      <section className="contact-essonova">
        <div>
          {/* <img src={contactimage} className="contact-image" alt=" essonova contact " />*/}
        </div>
        <div className="container">
          <div className="text-center wow fadeInDown">
            <div className="fw-bold section-tag ">Contact Us</div>
            <h3 className=" gradient__text">We are here to help</h3>
          </div>

          <div className="contact-form wow fadeInUp">
            <h2 className=" text-center section-tag fw-bold mb-3">Send a Message</h2>
            {loading ? (
              <RingLoader
                color="#21AEA7"
                loading={loading}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <form onSubmit={handleFormSubmit}>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      {formErrors.nameError && (
                        <div className="error-message" style={{ color: 'red' }}>{formErrors.nameError}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                      />
                      {formErrors.subjectError && (
                        <div className="error-message" style={{ color: 'red' }}>{formErrors.subjectError}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {formErrors.emailError && (
                        <div className="error-message" style={{ color: 'red' }}>{formErrors.emailError}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                      />
                      {formErrors.mobileError && (
                        <div className="error-message" style={{ color: 'red' }}>{formErrors.mobileError}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-group mb-3">
                      <textarea
                        rows="5"
                        className="form-control"
                        placeholder="Write Message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                      {formErrors.messageError && (
                        <div className="error-message" style={{ color: 'red' }}>{formErrors.messageError}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-check mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="consentCheckbox"
                        name="consent"
                        checked={formData.consent}
                        onChange={handleInputChange}
                      />
                      <label className="form-check-label" htmlFor="consentCheckbox">
                        Tick the checkbox, you agree to use our “Form” terms and consent to
                        cookie usage in the browser.
                      </label>
                      {formErrors.consentError && (
                        <div className="error-message" style={{ color: 'red' }}>{formErrors.consentError}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <button type="submit" className="essonova-btn">
                      Send a Message
                    </button>
                  </div>
                </div>
              </form>
            )}


            {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
            {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}
          </div>
        </div>
      </section>

      <section className="get-started wow bounceIn hidden-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className='gradient__text'>Ready to Explore Software Development Technology?</h3>
              <p>Our award-winning division is dedicated to transforming businesses through cutting-edge software development technology solutions. Discover the possibilities and elevate your organization.</p>

              <a href="/">
                <button type="button" className="essonova-btn">Contact Now!</button>
              </a>
            </div>
            <div className="col-md-6">
              <div className="map">
                <div className="embed-responsive embed-responsive-1by1">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3344.895992666838!2d-96.69829404729217!3d33.0328705140495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c19079f4ac375%3A0x3b0429fb1b92e72!2s2600%20K%20Ave%20%23262%2C%20Plano%2C%20TX%2075074%2C%20USA!5e0!3m2!1sen!2sin!4v1688659721114!5m2!1sen!2sin"
                    className="embed-responsive-item"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps Embed for 2600 K Ave #262, Plano, TX 75074, USA"
                ></iframe>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default ContactUs;
