import React, { useContext, useState } from 'react';
import { StateContext } from '../StateManager/StateManager';
import './admin_css/coursemanage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import AddCourse from './AddCourse';


const CourseManage = () => {
    const statemanager = useContext(StateContext);
    const [searchInput, setSearchInput] = useState('');

    const filteredCourses = statemanager.data_from_db.coursedata.state.filter((item) => {
        return (
            (searchInput === '' || ((item.program_name).toLowerCase().includes(searchInput.toLowerCase())))
        );
    });


    return (
        <div className="admin-coursemanage-container">
            {statemanager.adminstates.adminbuttons.add_edit_course.state && <AddCourse /> }
            <div className="admin-coursemanage-container__table">
                <table >
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Author</th>
                            <th>Created On</th>
                            <th>Phone</th>
                            <th style={{cursor:'pointer'}}
                            onClick={() => {
                                statemanager.adminstates.adminbuttons.edit_course_current_item.setState(null);
                                statemanager.adminstates.adminbuttons.add_edit_course.setState(true);
                                statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false);

                            }}>+<FontAwesomeIcon icon={faBook} /></th>
                            <th>
                                <input
                                    type='text'
                                    name='search'
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    placeholder='search by name'
                                    className='admin-course-search' />

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCourses.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.program_name}</td>
                                <td>{item.program_created_by}</td>
                                <td>{item.program_time}</td>
                                <td>
                                    <div className='edit-button'>
                                        <button className="btn btn-primary"
                                            onClick={() => {
                                                statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false);
                                                statemanager.adminstates.adminbuttons.edit_course_current_item.setState(item);
                                                statemanager.adminstates.adminbuttons.add_edit_course.setState(true);

                                            }}>
                                            Edit</button>
                                    </div>
                                </td>
                                <td>
                                    <div className='delete-button'>
                                        <button className="btn btn-danger"
                                            onClick={() => {
                                                statemanager.adminstates.adminbuttons.add_edit_course.setState(false);
                                                statemanager.adminstates.adminbuttons.delete_button.delete_component_current_data.setState(item);
                                                statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(true);


                                            }}>
                                            Delete</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CourseManage
