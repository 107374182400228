import React, { createContext, useState } from 'react'


const StateContext = createContext();

function StateManager(props) {
  const [showlogin, setShowLogin] = useState(false);
  const [showregistrationform, setShowRegistrationForm] = useState(false);
  const [forgetpassword, setForgetPassword] = useState(false);

  ///
  //Admin states
  ///
  const [show_admin_home, setAdminHome] = useState(false);
  const [show_admin_users, setAdminUsers] = useState(false);
  const [show_admin_courses, setAdminCourses] = useState(false);
  const [show_admin_contactrequests, setAdminContactRequests] = useState(false);
  const [show_admin_blogs, setAdminBlogs] = useState(false);
  const [show_subscribed, setShowSubscribed] = useState(false);


  ///Edit User
  const [show_edit_user, setShowEditUser] = useState(false);
  const [edit_user_current_data, setEditUserCurrentData] = useState();
  const [edit_update_status, setEditUpdateStatus] = useState(false);
  const [change_password,setChangePassword] = useState(false);


  //Profile Menu 
  const [showProfileMenu,setShowProfileMenu] = useState(false);

  ///Course
  //const [showaddCourse,setShowAddCourse] = useState(false);
  const [show_add_edit_course, setShowAddEditCourse] = useState(false);
  const [edit_course_current_data, setEditCourseCurrentData] = useState(null);

  ///Edit Blog
  const [show_add_edit_blog, setShowAddEditBlog] = useState(false);
  const [edit_blog_current_data, setEditBlogCurrentData] = useState();

  /// Delete Component
  const [delete_component_show, setDeleteComponentShow] = useState(false);
  const [delete_component_current_data, setDeleteComponentCurrentData] = useState();


  //ALERT BOX 
  const [alert_box_show, setAlertBoxShow] = useState(false);
  const [alert_box_message, setAlertBoxMessage] = useState("");
  const [alert_box_message_color, setAlertBoxMessageColor] = useState('green');

  /// Log In state and Log Out State
   const[isloggedin,setIsLoggedIn] = useState(false);

   ///
   ///Logged IN user details
   ///
   const [user_info,setUserInfo]=useState(null);
    


  ///
  ///
  /// DATA from DB
  const [userdata, setUserData] = useState([]);
  const [coursedata, setCourseData] = useState([]);
  const [blogdata, setBlogData] = useState([]);
  const [contactRequestsData,setContactRequestsData]=useState([]);
  const [subscribeData,setSubscribeData]=useState([]);


  ///
  /// USER PROFILE
  ///
  const [displayUserProfile,setDisplayUserProfile] = useState(false);
  const [editUserProfile,setEditUserProfile] = useState(false);


  ///
  ///
  ///
    const[isAdminLoggedIn,setIsAdminLoggedIn] = useState();
  // const[isUserLoggedIn,setIsUserLoggedIn] = useState();
  // const[isInstructorLoggedIn,setIsInstructorLoggedIn] = useState();

 ///TOKEN form LOGIN 
     const[ token,setToken] = useState();

   /// OTP HASH FROM SERVER
   const[userIdFromServerAccToEmail,setUserIdFromServerAccToEmail] = useState(null);




    // Data to Provide
  const data = {
    token : {state : token, setState : setToken},
    isAdminLoggedIn : {state :isAdminLoggedIn , setState : setIsAdminLoggedIn},
    loginstate : {state : isloggedin, setState : setIsLoggedIn},
    logged_in_user_data : {state : user_info , setState : setUserInfo},
    loginform: { state: showlogin, setState: setShowLogin },
    registerform: { state: showregistrationform, setState: setShowRegistrationForm },
    forgetpassword: { state: forgetpassword, setState: setForgetPassword },

    // state for user if form server when processing the forgot password
    userIdFromServerAccToEmail : {state : userIdFromServerAccToEmail,setState : setUserIdFromServerAccToEmail},


    //user profile
    displayUserProfile : {state : displayUserProfile, setState : setDisplayUserProfile},
    editUserProfile : {state : editUserProfile, setState : setEditUserProfile},
    changePassword : {state : change_password, setState : setChangePassword},
    showProfileMenu : {state : showProfileMenu, setState : setShowProfileMenu},


    alert_box:
    {
      alert_box_show: { state: alert_box_show, setState: setAlertBoxShow },
      alert_box_message: { state: alert_box_message, setState: setAlertBoxMessage },
      alert_box_message_color: { state: alert_box_message_color, setState: setAlertBoxMessageColor }
    },


    adminstates: {
      adminhome: { state: show_admin_home, setState: setAdminHome, },
      adminusers: { state: show_admin_users, setState: setAdminUsers, },
      admincourses: { state: show_admin_courses, setState: setAdminCourses, },
      admincontactrequests: { state: show_admin_contactrequests, setState: setAdminContactRequests, },
      adminblogs: { state: show_admin_blogs, setState: setAdminBlogs },
      adminsubscribed : {state : show_subscribed, setState : setShowSubscribed},
      adminbuttons:
      {
        add_edit_blog: { state: show_add_edit_blog, setState: setShowAddEditBlog },
        edit_blog_current_item: { state: edit_blog_current_data, setState: setEditBlogCurrentData },
        add_edit_course: { state: show_add_edit_course, setState: setShowAddEditCourse },
        edit_course_current_item: { state: edit_course_current_data, setState: setEditCourseCurrentData },
        edit_user: { state: show_edit_user, setState: setShowEditUser },
        edit_user_current_item: { state: edit_user_current_data, setState: setEditUserCurrentData },
        edit_component_update_status: { state: edit_update_status, setState: setEditUpdateStatus }
        ,
        delete_button: {
          delete_component_show: { state: delete_component_show, setState: setDeleteComponentShow },
          delete_component_current_data: { state: delete_component_current_data, setState: setDeleteComponentCurrentData },
        }

      }
    },

    data_from_db: {
      userdata: { state: userdata, setState: setUserData },
      coursedata: { state: coursedata, setState: setCourseData },
      blogdata: { state: blogdata, setState: setBlogData },
      contactRequestsData : { state : contactRequestsData,setState : setContactRequestsData},
      subscribeData : { state : subscribeData , setState : setSubscribeData}
    }
    
}


return (
  <StateContext.Provider value={data}>
    {props.children}
  </StateContext.Provider>
);
}

export { StateContext, StateManager };