// benefit section import
//import { data } from 'jquery';
import {
    Badge4k,
    BlockquoteRight,
    BorderBottom,
    BroadcastPin,
    BucketFill,
} from 'react-bootstrap-icons'; // Add more icons as needed

//instructor imports
import walter from '../assests/images/Instructor/Walter.png'
import sanjay from '../assests/images/Instructor/Sanjay Sir.png'

//partner import
import avaya from '../assests/images/partners/avaya.jpg';
import chicosfas from '../assests/images/partners/chicosfas-inc.jpg';
import ewomwn from '../assests/images/partners/e-women-network.jpg';
import golfsmith from '../assests/images/partners/golfsmith.jpg';
import merrill from '../assests/images/partners/merrill-lynch.jpg';
import pacificorp from '../assests/images/partners/pacificorp.jpg';
import thewall from '../assests/images/partners/The-Wall-Street-Journal.jpg';
import torchmarks from '../assests/images/partners/torchmarks-corporation.jpg';
import united from '../assests/images/partners/united-healthcare.jpg';
import xocommunication from '../assests/images/partners/xo-communications.jpg';
import skillweed from '../assests/images/partners/skillweed.png';

//basic bootcamp import

import Images_html from '../assests/images/programs/html5.png'
import Images_css from '../assests/images/programs/cssimage.png'
import Images_cSharp from '../assests/images/programs/Images_cSharp.png'
import Image_VScode from '../assests/images/programs/vscode.png'



//backend bootcamp
import Images_python from '../assests/images/programs/Image_python.png'
import Images_nodejs from '../assests/images/programs/Image_nodejs.png'
import Images_mysql from '../assests/images/programs/Image_mysql.png'
import Images_sqlserver from '../assests/images/programs/Image_sqlserver.png'
import Images_mongodb from '../assests/images/programs/Image_mongodb.png'
import Images_cicd from '../assests/images/programs/Image_cicd.png'
import Images_versioncontrol from '../assests/images/programs/Image_versioncontrol.png'

//frontend bootcamp import
import Images_bootStrap from '../assests/images/programs/bootstrap.png'
import Image_react from '../assests/images/programs/react.png'
import Image_JS from '../assests/images/programs/Image_JS.png'

//fullstack bootcamp import
import Image_aws from '../assests/images/programs/Image_aws.png'
import Image_azure from '../assests/images/programs/Image_Azure.png'


// course card import
import basic from '../assests/images/Course/Basics.webp';
import fend from '../assests/images/Course/frontend.png';
import bend from '../assests/images/Course/backend.png';
import fstack from '../assests/images/Course/fstack.png';

//testimonials
import sarthak from '../assests/images/testimonials/sarthaktestimonial.jpg'
import pkumar from '../assests/images/testimonials/mrktestimonial.jpg'
import default_img from '../assests/images/default-profile-img.png';




export const Benifitsdata = [
    {
        id: 1,
        icon: <BorderBottom />,
        title: 'Expert Instructors',
        Description:
            'Learn from experienced instructors who provide expert guidance and support throughout your learning journey.',
    },
    {
        id: 2,
        icon: <BroadcastPin />,
        title: 'Interactive Classes',
        Description:
            'Participate in interactive classes where you can practice speaking, listening, reading, and writing in your target.',
    },
    {
        id: 3,
        icon: <BucketFill />,
        title: 'Live Workshops',
        Description:
            'Attend weekly live workshops to reinforce your skills and engage in meaningful conversations with fellow learners.',
    },
    {
        id: 4,
        icon: <BroadcastPin />,
        title: 'Comprehensive Curriculum',
        Description:
            'Access a comprehensive curriculum designed by experts, covering all aspects of acquisition and fluency.',
    },
    {
        id: 5,
        icon: <Badge4k />,
        title: 'Practical Learning',
        Description: 'Enhance your skills by practicing real-world scenarios and cultural immersion, preparing you for global communication.',
    },
    {
        id: 6,
        icon: <BlockquoteRight />,
        title: 'Career Opportunities',
        Description:
            'Open doors to new career opportunities by becoming proficient in a foreign, expanding your job prospects in a globalized world.',
    },
];


////
// FAQ home page data
////

export const faq_home = {
    faq_data_left: [
        {
            collapsevalue: "collapseOne",
            question: "What is the purpose of this training program?",
            explanation: "This training program aims to help individuals improve their skills, learn new technologies, and prepare for job switches in the field of web development."
        },
        {
            collapsevalue: "collapseTwo",
            question: "How can I enroll in this training program?",
            explanation: "To enroll in this program, please visit our website and follow the registration instructions. You can also contact our support team for assistance."
        },
        {
            collapsevalue: "collapseThree",
            question: "What are the prerequisites for this program?",
            explanation: "There are no specific prerequisites for this program. It is suitable for beginners as well as experienced individuals looking to enhance their skills."
        },
        {
            collapsevalue: "collapseFour",
            question: "What topics are covered in the frontend development section?",
            explanation: "The frontend development section covers HTML, CSS, JavaScript, React.js, and responsive web design principles."
        }
    ]
    ,

    faq_data_right: [
        {
            collapsevalue: "collapseFive",
            question: "Is this program suitable for beginners?",
            explanation: "Yes, this program is designed to accommodate beginners and individuals with varying levels of experience."
        },
        {
            collapsevalue: "collapseSix",
            question: "What career opportunities can I explore after completing this program?",
            explanation: "Upon completion, you can pursue roles such as frontend developer, backend developer, full-stack developer, and more."
        },
        {
            collapsevalue: "collapseSeven",
            question: "How can I improve my skills for job switching?",
            explanation: "Discover strategies and resources to enhance your skills and successfully switch jobs."
        },
        {
            collapsevalue: "collapseEight",
            question: "What is the purpose of this training program?",
            explanation: "This training program aims to help individuals improve their skills, learn new technologies, and prepare for job switches in the field of web development."
        }
    ]

}

///
// 
///

///
// Instructor section data
///



export const instructors = [
    {
        id: 1,
        name: 'Walther H. Contreras',
        designation: 'Backend Instructor',
        description: 'Ex Solutions Integration Engineer and Product Manager, Microsoft Corporation, MCSE Networking BS Software Engineering, SMU University-USA',
        image: walter, // Replace with the actual image file path
    },
    {
        id: 2,
        name: 'Sanjay Kumar',
        designation: 'Frontend Instructor',
        description: 'Ex Solutions Integration Engineer, Microsoft Corporation MS Systems & Information BITS Pilani- India',
        image: sanjay, // Replace with the actual image file path
    },
    {
        id: 3,
        name: 'Rachel M',
        designation: 'Database Instructor',
        description: 'Microsoft Certified Expert,Ms computer science Yerevan university Armenia - Russia',
        image: default_img, // Replace with the actual image file path
    }
    // Add more instructors as needed
];



///
//  Partner data
///



export const partnerData = [
    {
        name: 'Partner 1',
        logoSrc: avaya,
    },
    {
        name: 'Partner 11',
        logoSrc: skillweed,
    },
    {
        name: 'Partner 2',
        logoSrc: chicosfas,
    },
    {
        name: 'Partner 3',
        logoSrc: ewomwn,
    },
    {
        name: 'Partner 4',
        logoSrc: golfsmith,
    },
    {
        name: 'Partner 5',
        logoSrc: merrill,
    },
    {
        name: 'Partner 6',
        logoSrc: pacificorp,
    },
    {
        name: 'Partner 7',
        logoSrc: thewall,
    },
    {
        name: 'Partner 8',
        logoSrc: torchmarks,
    },
    {
        name: 'Partner 9',
        logoSrc: united,
    },

    {
        name: 'Partner 10',
        logoSrc: xocommunication,
    }

    // Add more partners as needed
];



///
// Testimonials data
///
export const testimonialsData = [
    {
        id: 1,
        name: 'Praduman Kumar',
        designation: 'Software Developer',
        text: 'Learning from domain experts at e-neurotechlabs emphasizes practical coding and problem-solving, vital for software development. Despite the vast syllabus, mentors motivate and guide students, providing real-world experience through project discussions and team collaboration. This holistic approach equips learners with practical skills, accelerating their path towards career goals. e-neurotechlabs stands out for learning from the best.',
        image: pkumar
    },
    {
        id: 2,
        name: 'Sarthak Poswal',
        designation: 'Full Stack Developer',
        text: 'Got to learn from domain experts. They heavily emphasize on writing more code and problem solving which is exactly what you need to be a software developer. The syllabus was vast and I often felt overwhelmed but thanks to the essonova team and my mentors who always motivated me to work hard and guided me on my path. You get a real life working experience even when learning. Project discussion, team planning, analysis, report writing I get to be part of everything. I am sure the learning I have from here will help me achieve my goals faster. Essonova is best for learning from the best.',
        image: sarthak,
    },
    {
        id: 3,
        name: 'Rohan Gupta',
        designation: 'Full Stack Developer',
        text: 'E-NeuraTechLab\'s program has been a game-changer for me. The hands-on experience and mentorship have been invaluable.',
        image: default_img,
    },
    {
        id: 4,
        name: 'Mr. Kumar',
        designation: 'Software Engineer',
        text: 'E-NeuraTechLab\'s training gave me the skills and confidence to excel. The live workshops and expert guidance were top-notch.',
        image: default_img,
    },
    {
        id: 5,
        name: 'Priyatosh Kumar',
        designation: 'Database Engineer',
        text: 'I can\'t thank E-NeuraTechLab enough for the transformational learning experience. The portfolio project and mentorship were invaluable.',
        image: default_img,
    },
    {
        id: 6,
        name: 'Karla K',
        designation: 'Data Analyst',
        text: 'E-NeuraTechLab\'s program opened up new opportunities for me. The interactive workshops and community support were outstanding.',
        image: default_img,
    },
    // Add more testimonial objects as needed
];



///////////////////
///////////////////

//   ISA bootcamp 

///////////////////
///////////////////
const bootcampData = {
    "bootcamps": [
        {
            "id": 1,
            "name": "Fundamentals of Web Technology",
            "duration": "4 Weeks",
            "termsAndConditions": "As per industry recommendations, this is the ISA for the Fundamentals of Web Technology bootcamp.",
            "paymentStructure": "Payment for this bootcamp ranges from $500 to $5000.",
            "eligibilityCriteria": "To be eligible for this ISA, you must have computer literacy, good communication skills, a strong work ethic, and logical thinking abilities."
        },

        {
            "id": 2,
            "name": "Frontend Development",
            "duration": "3 Months",
            "termsAndConditions": "As per industry recommendations, this is the ISA for the frontend development bootcamp.",
            "paymentStructure": "Payment for this bootcamp ranges from $500 to $5000.",
            "eligibilityCriteria": "To be eligible for this ISA, you must have computer literacy, good communication skills, a strong work ethic, and logical thinking abilities."
        },
        {
            "id": 3,
            "name": "Backend Development",
            "duration": "4 Months",
            "termsAndConditions": "As per industry recommendations, this is the ISA for backend development bootcamp.",
            "paymentStructure": "Payment for this bootcamp ranges from $500 to $5000.",
            "eligibilityCriteria": "To be eligible for this ISA, you must have computer literacy, good communication skills, a strong work ethic, and logical thinking abilities."
        },
        {
            "id": 4,
            "name": "Full Stack Development",
            "duration": "4-6 Months",
            "termsAndConditions": "As per industry recommendations, this is the ISA for the full stack development bootcamp.",
            "paymentStructure": "Payment for this bootcamp ranges from $500 to $5000.",
            "eligibilityCriteria": "To be eligible for this ISA, you must have computer literacy, good communication skills, a strong work ethic, and logical thinking abilities."
        }
        // You can add more bootcamps with similar structures
    ]
};
export default bootcampData;


///////////////////
///////////////////

//   Backend bootcamp data

///////////////////
///////////////////



export const backend_data = {
    roadmapcard: [
        {
            week: 1,
            topic: 'Week 1: Introduction to Backend Development',
            content: [
                'Understanding Backend Development',
                'Introduction to Backend Technologies (Node.js, C#, Python)',
            ],
            assignments: ['Assignment: Set Up Your Backend Environment'],
            projects: ['Project: Hello World Backend'],
        },
        {
            week: 2,
            topic: 'Week 2: Backend Programming with Node.js',
            content: [
                'Node.js Fundamentals',
                'Building RESTful APIs with Express.js',
                'Building RESTful APIs with ASP .net Core Web APIs',
            ],
            assignments: ['Assignment: Create a Simple RESTful API'],
            projects: ['Project: Building a Backend API'],
        },
        {
            week: 3,
            topic: 'Week 3: Database Management',
            content: [
                'Introduction to Databases (SQL and NoSQL)',
                'Working with MongoDB and SQL Databases with CRUD Operations',
            ],
            assignments: ['Assignment: Create and Populate a Database'],
            projects: ['Project: Integrating Database with Backend'],
        },
        {
            week: 4,
            topic: 'Week 4: Version Control with Git',
            content: [
                'Introduction to Version Control with Git & Github',
                'Using Git for Collaborative Development and team collaboration',
            ],
            assignments: ['Assignment: Collaborative Git Repository'],
            projects: ['Project: Managing Code with Git'],
        },
        {
            week: 5,
            topic: 'Week 5: CI/CD and Deployment',
            content: [
                'Continuous Integration and Continuous Deployment (CI/CD) Concepts',
                'Setting Up CI/CD Pipelines and Automated Deployment',
            ],
            assignments: ['Assignment: Implement CI/CD for Your Backend'],
            projects: ['Project: Automating Deployment with CI/CD'],
        },
        {
            week: 6,
            topic: 'Week 6: Authentication and Security',
            content: [
                'User Authentication and Authorization',
                'Security Best Practices: Input Validation and Encryption',

            ],
            assignments: ['Assignment: Implement Authentication in Your API'],
            projects: ['Project: Securing Your Backend API'],
        },
        {
            week: 7,
            topic: 'Week 7: Error Handling and Logging',
            content: [
                'Handling Errors and Exceptions',
                'Logging and Monitoring in Backend Applications',
                'Versioning and Documentation',
            ],
            assignments: ['Assignment: Implement Error Handling and Logging'],
            projects: ['Project: Monitoring Your Backend App'],
        },
        {
            week: 8,
            topic: 'Week 8: Advanced Topics and Final Project',
            content: [
                'Performance Optimization Techniques',
                'Scaling and Load Balancing in Backend Systems',
            ],
            assignments: ['Assignment: Optimize and Scale Your Backend'],
            projects: ['Final Project: Building a Full-Featured Backend Application'],
        }
    ]
    ,
    technologyDetails: [
        {
            title: 'C#',
            logo: Images_cSharp,
            body: 'C# is a powerful programming language used for building APIs and backend applications. It is commonly used in combination with .NET for web development.',
        },
        {
            title: 'Node.js',
            logo: Images_nodejs,
            body: 'Node.js is a JavaScript runtime that allows you to build scalable and high-performance server-side applications.',
        },
        {
            title: 'Python',
            logo: Images_python,
            body: 'Python is a versatile programming language used for various purposes, including web development, scripting, and data analysis.',
        },
        {
            title: 'MongoDB',
            logo: Images_mongodb,
            body: 'MongoDB is a NoSQL database that is used to store and manage data in a flexible, schema-less format, making it suitable for a wide range of applications.',
        },
        {
            title: 'SQL Server',
            logo: Images_sqlserver,
            body: 'SQL Server is a relational database management system (RDBMS) developed by Microsoft. It is used for storing and managing structured data.',
        },
        {
            title: 'MySQL',
            logo: Images_mysql,
            body: 'MySQL is an open-source relational database management system (RDBMS) known for its reliability and performance.',
        },
        {
            title: 'Version Control',
            logo: Images_versioncontrol,
            body: 'Version control systems like Git are used to track changes in your codebase, collaborate with others, and manage different versions of your software.',
        },
        {
            title: 'CI/CD Pipeline',
            logo: Images_cicd,
            body: 'Continuous Integration (CI) and Continuous Deployment (CD) pipelines automate the process of building, testing, and deploying your software, ensuring a smooth and reliable release process.',
        }
    ]
    ,
    prerequisites: [
        '✔ Basic understanding of computer programming concepts',
        '✔ Desire to learn backend development',
        '✔ You need to plan for an eight-week commitment: Seek the support of your friends and family',
        '✔ You need to be able to bring your own laptop during the workshops and continue to code on it',
        '✔ Some previous programming experience in languages like JavaScript, C#, or Python is helpful but not required',
        // Add more prerequisites here
    ]
    ,

    faq_backend: {
        faq_data_left: [
            {
                collapsevalue: "collapseOne",
                question: "What is the purpose of this training program in Backend development?",
                explanation: "This training program aims to help individuals improve their skills in backend development, learn new technologies, and prepare for job opportunities in the field of backend web development."
            },
            {
                collapsevalue: "collapseTwo",
                question: "How can I enroll in this backend development training program?",
                explanation: "To enroll in this program, please visit our website and follow the registration instructions. You can also contact our support team for assistance with the enrollment process."
            },
            {
                collapsevalue: "collapseThree",
                question: "What are the prerequisites for this backend development program?",
                explanation: "There are no specific prerequisites for this program. It is suitable for beginners as well as experienced individuals looking to enhance their skills in backend development."
            },
            {
                collapsevalue: "collapseFour",
                question: "What topics are covered in the backend development section?",
                explanation: "The backend development section covers topics such as Node.js, Express.js, database management, version control, CI/CD, authentication, security, and more."
            }
        ]
        ,
        faq_data_right: [
            {
                collapsevalue: "collapseFive",
                question: "Is this program suitable for beginners in Backend development?",
                explanation: "Yes, this program is designed to accommodate beginners in backend development and individuals with varying levels of experience in backend technologies."
            },
            {
                collapsevalue: "collapseSix",
                question: "What career opportunities can I explore after completing this backend development program?",
                explanation: "Upon completion, you can pursue roles such as backend developer, full-stack developer, DevOps engineer, and more in the field of backend web development."
            },
            {
                collapsevalue: "collapseSeven",
                question: "How can I improve my backend development skills for job switching?",
                explanation: "Discover strategies and resources to enhance your backend development skills and successfully switch to backend development roles."
            },
            {
                collapsevalue: "collapseEight",
                question: "What is the duration of this backend development training program?",
                explanation: "The duration of this program is eight weeks, during which you will cover a comprehensive curriculum in backend development."
            }
        ]

    }
}


///////////////////
///////////////////


// Basic bootcam Data

///////////////////
///////////////////
///


export const basic_bootcamp_data = {
    roadmapcard: [
        {
            week: 1,
            topic: 'Week 1: Introduction to Web Development',
            content: [
                'Overview of Web Development',
                'HTML Fundamentals',
                'Introduction to Programming Languages (JavaScript, C#, etc.)',
            ],
            assignments: ['Assignment: Create a Simple HTML Page'],
            projects: ['Project: Personal Portfolio'],
        },
        {
            week: 2,
            topic: 'Week 2: CSS and Styling',
            content: [
                'CSS Basics',
                'CSS Layouts',
                'CSS Grid',
                'CSS Box-model',
                'Responsive Design',
            ],
            assignments: ['Assignment: Style Your Portfolio Page'],
            projects: ['Project: Responsive Website Design'],
        },
        {
            week: 3,
            topic: 'Week 3: Introduction to Backend Development',
            content: [
                'Backend vs. Frontend',
                'Introduction to Server-Side Programming',
                'Networking Fundamentals',
            ],
            assignments: ['Assignment: Create a Basic Server'],
            projects: ['Project: Simple Web Application with Backend'],
        },
        {
            week: 4,
            topic: 'Week 4: Hosting and Domains',
            content: [
                'Website Hosting Options',
                'Domain Registration and Management',
                'Deployment Strategies',

            ],
            assignments: ['Assignment: Deploy Your Website Online'],
            projects: ['Project: Buy a Domain and Host Your Website'],
        },
    ]
    ,

    technologyDetails: [
        {
            title: 'HTML Basics',
            logo: Images_html, // Replace Images_html with the appropriate logo/image for HTML.
            body: 'HTML (Hypertext Markup Language) is a fundamental markup language for creating web pages. It serves as the foundation for web development and is essential to learn for beginners.'
        },
        {
            title: 'CSS Fundamentals',
            logo: Images_css, // Replace Images_css with the appropriate logo/image for CSS.
            body: 'CSS (Cascading Style Sheets) is a crucial language for styling web pages. Mastering CSS basics is essential for controlling the appearance of your web content and making web page responsive in all screen sizes.'
        },
        {
            title: 'C# Programming Basics',
            logo: Images_cSharp, // Replace Images_cSharp with the appropriate logo/image for C#.
            body: 'C# is a versatile programming language developed by Microsoft. Learning C# basics is a great starting point for aspiring developers, as it can be used for various types of applications.'
        },
        {
            title: 'Visual Studio Code Essentials',
            logo: Image_VScode, // Replace Image_VScode with the appropriate logo/image for Visual Studio Code.
            body: 'Microsoft Visual Studio Code is a popular code editor. Understanding its essentials is crucial for efficient coding and productivity in your learning journey.'
        },
        // Add more technology details here with consistent language.
    ]
    ,



    prerequisites: [
        '✔ Basic understanding of computers',
        '✔ Desire to learn web development',
        '✔ You need to plan for a four-week commitment: Seek the support of your friends and family',
        '✔ You need to be able to bring your own laptop during the workshops and continue to code on it',
        '✔ No previous experience with HTML, CSS, or Bootstrap is required, but it is helpful if you have some programming experience',
        // Add more prerequisites here
    ],

    faq_basic: {
        faq_data_left: [
            {
                collapsevalue: "collapseOne",
                question: "What is web development and its basic concepts?",
                explanation: "Web development is the process of creating websites and web applications. It involves HTML for structuring content, CSS for styling, and JavaScript for interactivity."
            },
            {
                collapsevalue: "collapseTwo",
                question: "How can I start learning web development from scratch?",
                explanation: "To begin learning web development, you can start by studying HTML, CSS, and JavaScript. There are many online resources and tutorials available to help you get started."
            },
            {
                collapsevalue: "collapseThree",
                question: "Are there any prerequisites for learning web development?",
                explanation: "No specific prerequisites are required to start learning web development. It's suitable for beginners and those with programming knowledge."
            },
            {
                collapsevalue: "collapseFour",
                question: "What are key programming languages used in web development?",
                explanation: "The primary programming languages in web development include HTML for markup, CSS for styling, and JavaScript for adding interactivity to websites."
            }
        ]
        ,
        faq_data_right: [
            {
                collapsevalue: "collapseFive",
                question: "Is web development a good career choice for beginners?",
                explanation: "Yes, web development is an excellent career choice for beginners. It offers a wide range of opportunities and is in high demand."
            },
            {
                collapsevalue: "collapseSix",
                question: "What career options are available after learning web development basics?",
                explanation: "After learning the basics of web development, you can pursue roles such as front-end developer, back-end developer, web designer, or even a full-stack developer."
            },
            {
                collapsevalue: "collapseSeven",
                question: "How can I improve my programming skills for web development?",
                explanation: "To enhance your programming skills for web development, practice regularly, work on projects, and explore advanced topics like frameworks and libraries."
            },
            {
                collapsevalue: "collapseEight",
                question: "Why should I learn web development basics?",
                explanation: "Learning web development basics is essential to understand the foundation of creating websites and web applications, which is a valuable skill in today's digital world."
            }
        ]
    }
}





//////////////////////////////
//////////////////////////////

// Frontend Bootcamp data

/////////////////////////////
////////////////////////////


export const frontend_bootcamp_data = {
    roadmapcard: [
        {
            week: 1,
            topic: 'Week 1: Introduction to Frontend Development',
            content: [
                'Overview of Frontend Development',
                'HTML Fundamentals',
                'CSS Basics',
                'Introduction to Responsive Design',
            ],
            assignments: ['Assignment: Create a Basic HTML/CSS Web Page'],
            projects: ['Project: Personal Portfolio Page'],
        },
        {
            week: 2,
            topic: 'Week 2: Advanced CSS and Layouts',
            content: [
                'CSS Layout Techniques',
                'Flexbox and Grid Layouts',
                'CSS Frameworks (e.g., Bootstrap)',
            ],
            assignments: ['Assignment: Enhance Your Portfolio Page with Advanced CSS'],
            projects: ['Project: Build a Responsive Website Using a CSS Framework'],
        },
        {
            week: 3,
            topic: 'Week 3: JavaScript Essentials',
            content: [
                'JavaScript Basics',
                'DOM Manipulation',
                'Event Handling',
                'Introduction to Frontend Tooling (e.g., Webpack)',
            ],
            assignments: ['Assignment: Add Interactivity to Your Website with JavaScript'],
            projects: ['Project: Create an Interactive Web Application'],
        },
        {
            week: 4,
            topic: 'Week 4: Frontend Frameworks (e.g., React)',
            content: [
                'Introduction to Frontend Frameworks',
                'React.js Fundamentals',
                'Building User Interfaces with React',
            ],
            assignments: ['Assignment: Build a Simple React Component'],
            projects: ['Project: Develop a Single-Page Application with React'],
        },
        {
            week: 5,
            topic: 'Week 5: Advanced React and State Management',
            content: [
                'State and Props in React',
                'Managing Application State (e.g., Redux)',
            ],
            assignments: ['Assignment: Implement State Management in Your React App'],
            projects: ['Project: Enhance Your React Application with Advanced Features'],
        },
        {
            week: 6,
            topic: 'Week 6: Deployment and Optimization',
            content: [
                'Website Deployment Strategies',
                'Performance Optimization',
                'Showcase Your Final Project',
            ],
            assignments: ['Assignment: Deploy Your React Application'],
            projects: ['Project: Optimize and Deploy Your React Web App'],
        },
    ]
    ,


    technologyDetails: [
        {
            title: 'HTML Essential',
            logo: Images_html,
            body: 'HTML (Hypertext Markup Language) is the foundation of web development. Understanding HTML basics is vital for frontend developers as it structures web content.'
        },
        {
            title: 'CSS Mastery',
            logo: Images_css,
            body: 'CSS (Cascading Style Sheets) is crucial for frontend development. Proficiency in CSS is key to controlling web page styling and ensuring responsiveness.'
        },
        {
            title: 'Bootstrap Framework',
            logo: Images_bootStrap,
            body: 'Bootstrap is a versatile front-end framework. It provides reusable code in HTML, CSS, and JavaScript, enabling quick development of responsive websites.'
        },
        {
            title: 'JavaScript Basics',
            logo: Image_JS,
            body: 'JavaScript is pivotal for frontend developers. Learning JavaScript essentials is crucial to adding interactivity, make functional and dynamic features to web apps.'
        },
        {
            title: 'Frontend Frameworks',
            logo: Image_react,
            body: 'Frontend frameworks like React and Angular are essential tools. Exploring these frameworks enhances your ability to create modern web applications.'
        },
        {
            title: 'Code Editors',
            logo: Image_VScode,
            body: 'Code editors like Visual Studio Code are indispensable for frontend development. Proficiency with code editors streamlines your workflow.'
        },
    ]
    ,

    prerequisites: [
        '✔ Basic understanding of computers',
        '✔ Eagerness to learn frontend development',
        '✔ Commitment to a Six-week learning schedule',
        '✔ Access to a laptop for coding during the workshops and practice',
        '✔ While no prior experience with HTML, CSS, or Bootstrap is necessary, having some programming knowledge is beneficial',
        // Add more frontend development-specific prerequisites here
    ]
    ,

    frontend_faq: {
        faq_data_left: [
            {
                collapsevalue: "collapseOne",
                question: "What is frontend development and its fundamental concepts?",
                explanation: "Frontend development involves creating the user interface and user experience of websites and web applications. It encompasses HTML for structuring content, CSS for styling, and JavaScript for adding interactivity."
            },
            {
                collapsevalue: "collapseTwo",
                question: "How can I start learning frontend development from scratch?",
                explanation: "To begin learning frontend development, you can start with the basics of HTML, CSS, and JavaScript. Numerous online resources and tutorials are available to help you start your journey."
            },
            {
                collapsevalue: "collapseThree",
                question: "Are there any prerequisites for learning frontend development?",
                explanation: "No specific prerequisites are required to start learning frontend development. It's accessible to beginners and those with some programming knowledge."
            },
            {
                collapsevalue: "collapseFour",
                question: "What are the primary programming languages used in frontend development?",
                explanation: "Frontend development primarily relies on HTML for content structure, CSS for styling, and JavaScript for creating interactive web interfaces."
            }
        ]
        ,
        faq_data_right: [
            {
                collapsevalue: "collapseFive",
                question: "Is frontend development a suitable career choice for beginners?",
                explanation: "Yes, frontend development is an excellent career choice for beginners. It offers a broad range of opportunities and is in high demand due to its critical role in web development."
            },
            {
                collapsevalue: "collapseSix",
                question: "What career options are available after learning frontend development basics?",
                explanation: "After mastering the basics of frontend development, you can pursue roles such as front-end developer, web designer, UI/UX designer, or continue your journey to become a full-stack developer."
            },
            {
                collapsevalue: "collapseSeven",
                question: "How can I enhance my programming skills for frontend development?",
                explanation: "To improve your programming skills for frontend development, practice regularly, work on projects, and explore advanced topics like frontend frameworks such as React or Angular."
            },
            {
                collapsevalue: "collapseEight",
                question: "Why should I learn the fundamentals of frontend development?",
                explanation: "Learning the fundamentals of frontend development is crucial to build a strong foundation for creating visually appealing and interactive web interfaces, which are essential in the digital age."
            }
        ]
    }
}




/////////////////////
//////////////////////

// Full Stack Bootcamp data

////////////////////////
///////////////////////



export const fullstack_bootcamp_data = {
    roadmapcard: [
        {
            week: 1,
            topic: 'Week 1-2: Introduction to Frontend Development',
            content: [
                'Overview of Frontend Development',
                'HTML Fundamentals',
                'CSS Basics',
                'Introduction to Responsive Design',
            ],
            assignments: ['Assignment: Create a Basic HTML/CSS Web Page'],
            projects: ['Project: Personal Portfolio Page'],
        },
        {
            week: 2,
            topic: 'Week 2-3: Advanced CSS and Layouts',
            content: [
                'CSS Layout Techniques',
                'Flexbox and Grid Layouts',
                'CSS Frameworks (e.g., Bootstrap)',
            ],
            assignments: ['Assignment: Enhance Your Portfolio Page with Advanced CSS'],
            projects: ['Project: Build a Responsive Website Using a CSS Framework'],
        },
        {
            week: 3,
            topic: 'Week 3-4: JavaScript Essentials',
            content: [
                'JavaScript Basics',
                'DOM Manipulation',
                'Event Handling',
                'Introduction to Frontend Tooling (e.g., Webpack)',
            ],
            assignments: ['Assignment: Add Interactivity to Your Website with JavaScript'],
            projects: ['Project: Create an Interactive Web Application'],
        },
        {
            week: 4,
            topic: 'Week 4-5: Frontend Frameworks (e.g., React)',
            content: [
                'Introduction to Frontend Frameworks',
                'React.js Fundamentals',
                'Building User Interfaces with React',
            ],
            assignments: ['Assignment: Build a Simple React Component'],
            projects: ['Project: Develop a Single-Page Application with React'],
        },
        {
            week: 5,
            topic: 'Week 5-6: Advanced React and State Management',
            content: [
                'State and Props in React',
                'Managing Application State (e.g., Redux)',
            ],
            assignments: ['Assignment: Implement State Management in Your React App'],
            projects: ['Project: Enhance Your React Application with Advanced Features'],
        },
        {
            week: 6,
            topic: 'Week 6-7: Deployment and Optimization',
            content: [
                'Website Deployment Strategies',
                'Performance Optimization',
                'Showcase Your Final Project',
            ],
            assignments: ['Assignment: Deploy Your React Application'],
            projects: ['Project: Optimize and Deploy Your React Web App'],
        },
        {
            week: 7,
            topic: 'Week 8: Introduction to Backend Development',
            content: [
                'Understanding Backend Development',
                'Introduction to Backend Technologies (Node.js, C#, Python)',
            ],
            assignments: ['Assignment: Set Up Your Backend Environment'],
            projects: ['Project: Hello World Backend'],
        },
        {
            week: 8,
            topic: 'Week 9-10: Backend Programming with Node.js',
            content: [
                'Node.js Fundamentals',
                'Building RESTful APIs with Express.js',
                'Building RESTful APIs with ASP .net Core Web APIs',
            ],
            assignments: ['Assignment: Create a Simple RESTful API'],
            projects: ['Project: Building a Backend API'],
        },
        {
            week: 9,
            topic: 'Week 11-12: Database Management',
            content: [
                'Introduction to Databases (SQL and NoSQL)',
                'Working with MongoDB and SQL Databases with CRUD Operations',
            ],
            assignments: ['Assignment: Create and Populate a Database'],
            projects: ['Project: Integrating Database with Backend'],
        },
        {
            week: 10,
            topic: 'Week 13-14: Version Control with Git',
            content: [
                'Introduction to Version Control with Git & Github',
                'Using Git for Collaborative Development and team collaboration',
            ],
            assignments: ['Assignment: Collaborative Git Repository'],
            projects: ['Project: Managing Code with Git'],
        },
        {
            week: 11,
            topic: 'Week 15-16: CI/CD and Deployment',
            content: [
                'Continuous Integration and Continuous Deployment (CI/CD) Concepts',
                'Setting Up CI/CD Pipelines and Automated Deployment',
            ],
            assignments: ['Assignment: Implement CI/CD for Your Backend'],
            projects: ['Project: Automating Deployment with CI/CD'],
        },
        {
            week: 12,
            topic: 'Week 17-18: Authentication and Security',
            content: [
                'User Authentication and Authorization',
                'Security Best Practices: Input Validation and Encryption',

            ],
            assignments: ['Assignment: Implement Authentication in Your API'],
            projects: ['Project: Securing Your Backend API'],
        },
        {
            week: 13,
            topic: 'Week 19-20: Error Handling and Logging',
            content: [
                'Handling Errors and Exceptions',
                'Logging and Monitoring in Backend Applications',
                'Versioning and Documentation',
            ],
            assignments: ['Assignment: Implement Error Handling and Logging'],
            projects: ['Project: Monitoring Your Backend App'],
        },
        {
            week: 14,
            topic: 'Week 21-22: Advanced Topics and Final Project',
            content: [
                'Performance Optimization Techniques',
                'Scaling and Load Balancing in Backend Systems',
            ],
            assignments: ['Assignment: Optimize and Scale Your Backend'],
            projects: ['Final Project: Building a Full-Featured Backend Application'],
        },
        {
            week: 15,
            topic: 'Week 23-24: DevOps Practices',
            content: [
                'Introduction to DevOps Principles',
                'Infrastructure as Code (IaC)',
                'Configuration Management',
                'Containerization with Docker',
            ],
            assignments: ['Assignment: Implement Infrastructure as Code'],
            projects: ['Project: Containerize and Deploy Your Application'],
        },
        {
            week: 16,
            topic: 'Week 25-26: Cloud Computing and Deployment',
            content: [
                'Cloud Service Providers (e.g., AWS, Azure, GCP)',
                'Deploying Applications to the Cloud',
                'Scalability and Auto-scaling',
                'Serverless Computing',
            ],
            assignments: ['Assignment: Deploy Your Application to the Cloud'],
            projects: ['Project: Implement Serverless Functions in Your Application'],
        },

    ]
    ,

    technologyDetails: [
        {
            title: 'HTML Essential',
            logo: Images_html,
            body: 'HTML (Hypertext Markup Language) is the foundation of web development. Understanding HTML basics is vital for frontend developers as it structures web content.'
        },
        {
            title: 'CSS Mastery',
            logo: Images_css,
            body: 'CSS (Cascading Style Sheets) is crucial for frontend development. Proficiency in CSS is key to controlling web page styling and ensuring responsiveness.'
        },
        {
            title: 'Bootstrap Framework',
            logo: Images_bootStrap,
            body: 'Bootstrap is a versatile front-end framework. It provides reusable code in HTML, CSS, and JavaScript, enabling quick development of responsive websites.'
        },
        {
            title: 'JavaScript Basics',
            logo: Image_JS,
            body: 'JavaScript is pivotal for frontend developers. Learning JavaScript essentials is crucial to adding interactivity, make functional and dynamic features to web apps.'
        },
        {
            title: 'Frontend Frameworks',
            logo: Image_react,
            body: 'Frontend frameworks like React and Angular are essential tools. Exploring these frameworks enhances your ability to create modern web applications.'
        },
        {
            title: 'Code Editors',
            logo: Image_VScode,
            body: 'Code editors like Visual Studio Code are indispensable for frontend development. Proficiency with code editors streamlines your workflow.'
        },
        {
            title: 'C#',
            logo: Images_cSharp,
            body: 'C# is a powerful programming language used for building APIs and backend applications. It is commonly used in combination with .NET for web development.',
        },
        {
            title: 'Node.js',
            logo: Images_nodejs,
            body: 'Node.js is a JavaScript runtime that allows you to build scalable and high-performance server-side applications.',
        },
        {
            title: 'Python',
            logo: Images_python,
            body: 'Python is a versatile programming language used for various purposes, including web development, scripting, and data analysis.',
        },
        {
            title: 'MongoDB',
            logo: Images_mongodb,
            body: 'MongoDB is a NoSQL database that is used to store and manage data in a flexible, schema-less format, making it suitable for a wide range of applications.',
        },
        {
            title: 'SQL Server',
            logo: Images_sqlserver,
            body: 'SQL Server is a relational database management system (RDBMS) developed by Microsoft. It is used for storing and managing structured data.',
        },
        {
            title: 'MySQL',
            logo: Images_mysql,
            body: 'MySQL is an open-source relational database management system (RDBMS) known for its reliability and performance.',
        },
        {
            title: 'Version Control',
            logo: Images_versioncontrol,
            body: 'Version control systems, such as Git, are essential tools used to track changes in your codebase, facilitate collaboration with teammates, and effectively manage different versions of your software projects.',
        },
        {
            title: 'CI/CD Pipeline',
            logo: Images_cicd,
            body: 'Continuous Integration (CI) and Continuous Deployment (CD) pipelines automate software building, testing, and deployment for a streamlined release workflow.',
        },
        {
            title: 'Cloud Computing with Azure',
            logo: Image_azure,
            body: 'Cloud computing services, exemplified by Microsoft Azure, deliver scalable access to computing resources, enabling flexible application deployment and management.',
        },
        {
            title: 'Cloud Computing with AWS',
            logo: Image_aws,
            body: 'Cloud computing services, as exemplified by Amazon AWS, provide developers with servers, storage solutions, databases, and beyond, reducing infrastructure management complexities.',
        }


    ]
    ,
    prerequisites: [
        '✔ Solid foundation in front-end development, including HTML, CSS, and JavaScript',
        '✔ Familiarity with back-end technologies like Node.js, Python, C# or expressjs',
        '✔ Proficiency in using version control systems like Git',
        '✔ Understanding of databases and SQL or NoSQL concepts',
        '✔ Knowledge of web application architecture and RESTful APIs',
        '✔ Ability to work with both front-end and back-end development tools and frameworks',
        '✔ Basic knowledge of cloud computing platforms such as AWS, Azure, or Google Cloud',
        '✔ Strong problem-solving skills and logical thinking',
        '✔ Effective communication and collaboration abilities for team-based projects',
    ]
    ,

    fullstack_faq: {
        faq_data_left: [
            {
                collapsevalue: "collapseOne",
                question: "What is the purpose of this training program for Full Stack Development?",
                explanation: "The purpose of this training program is to empower individuals to level up their skills in full-stack development, gain expertise in both front-end and back-end technologies, and prepare for career advancements in the world of web development."
            },
            {
                collapsevalue: "collapseTwo",
                question: "How can I enroll in this program for Full Stack Development?",
                explanation: "Enrolling in this program is easy. Simply visit our website and follow the registration instructions. Our support team is also available to assist you throughout the process."
            },
            {
                collapsevalue: "collapseThree",
                question: "What are the skill prerequisites for this program?",
                explanation: "While there are no strict prerequisites, some prior knowledge of programming concepts and familiarity with web development basics will be beneficial. This program is designed to accommodate both beginners and experienced individuals looking to level up their full-stack development skills."
            },
            {
                collapsevalue: "collapseFour",
                question: "What topics are covered in the full-stack development section?",
                explanation: "The full-stack development section covers a wide range of topics, including HTML, CSS, JavaScript, React.js, Node.js, databases, API development, and much more. You'll acquire the skills needed to excel as a full-stack developer."
            }
        ],

        faq_data_right: [
            {
                collapsevalue: "collapseFive",
                question: "Is this program suitable for beginners in Full Stack Development?",
                explanation: "Absolutely! This program is designed to be inclusive, welcoming beginners as well as individuals with varying levels of experience in full-stack development. We provide comprehensive guidance to help you progress effectively."
            },
            {
                collapsevalue: "collapseSix",
                question: "What career opportunities can I explore after completing this program and leveling up my skills?",
                explanation: "Upon completing this program, you can explore exciting career opportunities as a full-stack developer, web application developer, software engineer, or even transition into roles that require expertise in both front-end and back-end technologies. The possibilities are vast."
            },
            {
                collapsevalue: "collapseSeven",
                question: "How can I further level up my skills in full-stack development?",
                explanation: "Discover strategies and resources within the program to level up your full-stack development skills. We provide guidance, projects, and resources to help you advance your career and expertise in this dynamic field."
            },
            {
                collapsevalue: "collapseEight",
                question: "What is the primary goal of this training program for Full Stack Development?",
                explanation: "The primary goal of this training program is to enable individuals to elevate their skills, embrace full-stack development, and prepare for career growth in the rapidly evolving web development industry. It's your pathway to becoming a proficient full-stack developer."
            }
        ]
    }
}



///////////////////////
///////////////////////
// Course Card data
//////////////////////
//////////////////////

export const course_card_data = [
    {
        img: fstack,
        title: 'Full-Stack Development',
        description: "Full-stack development combines front-end and back-end skills, allowing developers to create entire web applications, from the user interface to server-side functionality, or dtabase like Sql Server and my sql , mogoDB is used to ensuring a complete and functional system.",
        path: "/programs/fullstack-development-bootcamp"
    },
    {
        img: basic,
        title: 'Web Development Fundamentals',
        description: 'Web development involves creating the user interface and user experience of a website or web application using technologies like HTML, CSS, and JavaScript for client-side functionality and design.',
        path: "/programs/web-development-fundamentals-bootcamp"

    },
    {
        img: fend,
        title: 'Frontend Development',
        description: 'Frontend development involves creating the user interface and user experience of a website programming languages, frameworks, and technologies  or web application using technologies like HTML, CSS, and JavaScript for client-side functionality and design.',
        path: "/programs/frontend-development-bootcamp"
    },
    {
        img: bend,
        title: 'Backend Development',
        description: 'Back-end development involves building the server-side logic, databases, and infrastructure that power web applications,programming languages, frameworks, and technologies  often using languages like Python, Ruby, PHP, C# or JavaScript with Node.js.',
        path: "/programs/backend-development-bootcamp"
    }
]