import React, { useContext } from 'react'
import '../../assests/css/style.css';
import dimg from '../../assests/images/default-profile-img.png'
import { StateContext } from '../StateManager/StateManager';

const ProfileIcon = () => {
    const statemanager = useContext(StateContext);

    let username = "";
    let image =dimg;
    if (sessionStorage.getItem('loginData')) {
      const storedData = JSON.parse(sessionStorage.getItem('loginData'));
      username = storedData.data.fname;
      if(storedData.data.image.length>1000)
      image = storedData.data.image
    }

  return (
    
    < div className='profile-icon-component' onClick={()=>{
      statemanager.showProfileMenu.setState(!statemanager.showProfileMenu.state)}}>
        <span className='profile-icon-component-text'><p>Howdy , <b>{username} </b> </p></span>
          <img className='profile-icon' src={image} alt ="user profile"/>
    </div>
  )
}

export default ProfileIcon;
