import React, { useContext } from 'react'
import './admin_css/adminhome.css'
import { StateContext } from '../StateManager/StateManager'

const AdminHome = () => {
    const statemanager = useContext(StateContext);
    return (
        <div className='admin-home-container'>
            <div className="upper">
                <div className="left">
                    <h3>Registered User : {statemanager.data_from_db.userdata.state.length}</h3>

                </div>
                <div className="right">
                <h3>Total Courses : {statemanager.data_from_db.coursedata.state.length}</h3>
                </div>

            </div>
           <div className='verticalline'></div>
            <div className="lower">
                <div className="left">
                <h3>Total Blogs : {statemanager.data_from_db.blogdata.state.length}</h3>
                </div>
                <div className="right">
                <h3>Recent Contact Request : {statemanager.data_from_db.subscribeData.state.length}</h3>
                </div>

            </div>

        </div>
  )
}

export default AdminHome
