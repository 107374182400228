import React, { useContext, useState } from 'react';
import selecticon from '../../assests/images/blog/icons/arrowmix.png';
import { StateContext } from '../../components/StateManager/StateManager';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const Blogs = () => {
  window.scrollTo(0, 0);
  const [selectcategory, setSelectCategory] = useState('All'); // Default category
  const [showcategoryoptions, setShowCategoryOptions] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const statemanager = useContext(StateContext);

  const blogdata = statemanager.data_from_db.blogdata.state;

  const togglecategoryshow = () => {
    setShowCategoryOptions(!showcategoryoptions);
  };

  const updateCategory = (category) => {
    setSelectCategory(category);
    setShowCategoryOptions(false); // Close the category options
  };

  const filteredBlogPosts = blogdata.filter((item) => {
    return (
      (selectcategory === 'All' || item.category === selectcategory) &&
      (item.blog_title.toLowerCase().includes(searchInput.toLowerCase()) ||
        item.blog_description.toLowerCase().includes(searchInput.toLowerCase()))
    );
  });


  return (
    <div className="blogs-container">
      <div className="section-tag text-center fw-bold">Case Studies & Insights</div>
      <h3 className="gradient__text text-center">Articles daily updated</h3><hr />
      <div className="row">
        {/* Category selection */}
        <div className="col-md-3">
          <div className="category-selection">
            <button className="btn btn-category" onClick={togglecategoryshow}>
              {selectcategory} <img src={selecticon} alt="FILTER" />
            </button>
            {showcategoryoptions && (
              <div className="category-options">
                <button className="btn btn-light" onClick={() => updateCategory('All')}>
                  All
                </button>
                <button className="btn btn-light" onClick={() => updateCategory('Artificial Intelligence')}>
                  Artificial Intelligence
                </button>
                <button className="btn btn-light" onClick={() => updateCategory('Latest in Tech')}>
                  Latest in Tech
                </button>
                <button className="btn btn-light" onClick={() => updateCategory('Web Development')}>
                  Web Development
                </button>
                <button className="btn btn-light" onClick={() => updateCategory('Software Development')}>
                  Software Development
                </button>
                <button className="btn btn-light" onClick={() => updateCategory('Cyber Security')}>
                  Cyber Security
                </button>
                <button className="btn btn-light" onClick={() => updateCategory('Skill Development')}>
                  Skill Development
                </button>
                {/* Add more categories here */}
              </div>
            )}
          </div>
        </div>

        <div className="col-md-9">
          <div className="row">
            <div className="col-12 col-md-4">
              {/* Search input */}
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            {filteredBlogPosts.map((item) => (
              <div className="col" key={item.id}>
                <div className="card">
                  <img src={item.blog_image} className="card-img-top" alt="blog-category" />
                  <div className="card-body">
                    <h2 className="card-title">{item.blog_title}</h2>
                    <p className="card-text">
                      Author: {item.author_name}, Date: {item.blog_created_date.substring(0, 10)}
                    </p>
                    <p className="card-text">{parse(item.blog_description)}</p>
                    <Link to={`/blog/${item.blog_title.toLowerCase().replace(/ /g, '-')}`}>
                      <button className="essonova-btn">Read</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
