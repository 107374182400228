import React, { useContext, useState } from 'react';
import { StateContext } from '../StateManager/StateManager';
import './admin_css/usermanage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const UsersManage = () => {
    const statemanager = useContext(StateContext);
    const [searchInput, setSearchInput] = useState('');
    const [currentpageno,setCurrentPageNo] = useState(1);

    const dataperpage=10;

    let start_index = currentpageno === 1 ? 0 : (currentpageno*dataperpage-dataperpage);
    let end_index =  currentpageno === 1 ? 10 : (currentpageno*dataperpage);

    const userdata = statemanager.data_from_db.userdata.state;
    const totalpages=  Math.ceil((userdata.length)/dataperpage);
    let currentdata= userdata.slice(start_index,end_index);

    const filteredUser = currentdata.filter((item) => {
        return (
            (searchInput === '' || ((item.fname + ' ' + item.lname).toLowerCase().includes(searchInput.toLowerCase())))
        );
    });

    return (
        <div className="admin-usermanage-container">
            <div className="admin-usermanage-container__table">
                <table >
                    <thead style={{ position: 'sticky', top: 0 }}>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Username</th>
                            <th>Phone</th>
                            <th>Course Enrolled In</th>
                            <th style={{cursor:'pointer'}}
                            onClick={() => {
                                statemanager.adminstates.adminbuttons.edit_user.setState(false);
                                statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false);
                                statemanager.registerform.setState(true);

                            }}>+<FontAwesomeIcon icon={faUser} /></th>
                            <th>
                                <input
                                    type='text'
                                    name='search'
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    placeholder='search by name'
                                    className='admin-user-search' />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUser.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.fname + " " + item.lname}</td>
                                <td>{item.email}</td>
                                <td>{item.username}</td>
                                <td>{item.phone}</td>
                                <td>{item.programName}</td>
                                <td>
                                    <div className='edit-button'>
                                        <button className="btn btn-primary"
                                            onClick={() => {
                                                statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false);
                                                statemanager.adminstates.adminbuttons.edit_user_current_item.setState(item);
                                                statemanager.adminstates.adminbuttons.edit_user.setState(true);

                                            }}>
                                            Edit</button>
                                    </div>
                                </td>
                                <td>
                                    <div className='delete-button'>
                                        <button className="btn btn-danger"
                                            onClick={() => {
                                                statemanager.adminstates.adminbuttons.edit_user.setState(false);
                                                statemanager.adminstates.adminbuttons.delete_button.delete_component_current_data.setState(item);
                                                statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(true);


                                            }}>
                                            Delete</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="admin-usermanage-container__page-numbers">
                <div className="prev-page-button" onClick={()=>{
                    currentpageno===1?setCurrentPageNo(1):setCurrentPageNo(currentpageno-1)
                }}>Prev</div>
                <div className="current-page-number">{currentpageno}&nbsp;/&nbsp;{totalpages}</div>

                <div className="next-page-button" onClick={()=>{
                    currentpageno===totalpages?setCurrentPageNo(totalpages):setCurrentPageNo(currentpageno+1)
                }}>Next</div>
            </div>
        </div>
    );
};

export default UsersManage;
