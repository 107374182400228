import React from 'react'
import '../../assests/css/style.css';
import { Link } from 'react-router-dom'
import { course_card_data } from '../../context/context';

const Dropdown = ({ondrop}) => {
  return (
    <div className='dropdown' onMouseEnter={()=>ondrop(true)} onMouseLeave={()=>ondrop(false)}>
        {course_card_data.map((item,index)=>{
            return(
                <li key={index}>
                  <Link to={item.path}> 
                  <img src={item.img} alt={item.title}/>{item.title}
                  </Link>
                 </li>
                  
            )
        })}



    </div>
  )
}

export default Dropdown;
