import React from 'react';
import Image_frontend from '../../../assests/images/programs/fend.png';
import ParrentComponent from '../CommanComponent/ParrentComponent';
import Syllabus from '../../../components/Syllabus/Syllabus';
import Roadmap from '../../../components/Roadmap/Roadmap';
import Faq from '../../../components/Faq/Faq';
import { frontend_bootcamp_data } from '../../../context/context';


const Frontend = () => {
    window.scrollTo(0, 0);

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center">
                <img src={Image_frontend} alt='loading...' style={{ height: '19em' }} />
            </div>
            <div className="text-center">
                <h1 className="gradient__text p-2">Learn the Front-end Development Technology</h1>
                <p>HTML, CSS, and Bootstrap, for aspiring Front-end Developers. Build your foundation with these three pillars of the Internet and ready yourself for the next training Program.</p>
            </div>
            <ParrentComponent
                roadmapcard={frontend_bootcamp_data.roadmapcard}
                technologyDetails={frontend_bootcamp_data.technologyDetails}
                prerequisites={frontend_bootcamp_data.prerequisites}
            />
            <Roadmap />
            <Faq data = {frontend_bootcamp_data.frontend_faq} />
            <Syllabus />
        </div>
    );
}

export default Frontend;
