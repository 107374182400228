import React, { useContext } from 'react';
import ProgramSchedule from '../CommanComponent/ProgramSchedule'
import { StateContext } from '../../../components/StateManager/StateManager';


const RoadmapCard = ({ data }) => {
  return (
    <div className="col-md-3">
      <div className="roadmap-card mt-3">
        <h3>{data.topic}</h3>
        <ul>
          {data.content && data.content.map((contentItem, index) => (
            <li key={index}>{contentItem}</li>
          ))}
        </ul>
        {data.assignments && data.assignments.length > 0 && (
          <div>
            <ul>
              {data.assignments.map((assignment, index) => (
                <li key={index}>{assignment}</li>
              ))}
            </ul>
          </div>
        )}
        {data.projects && data.projects.length > 0 && (
          <div>
            <ul>
              {data.projects.map((project, index) => (
                <li key={index}>{project}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};



export default function ParrentComponent({ technologyDetails, prerequisites, roadmapcard , path}) {
  const statemanager = useContext(StateContext);

  const paymentportal = ()=>{ 
    statemanager.alert_box.alert_box_show.setState(true);
    statemanager.alert_box.alert_box_message.setState('Under Maintenance');
    statemanager.alert_box.alert_box_message_color.setState('red');
    
    setTimeout(() => {
      statemanager.alert_box.alert_box_show.setState(false);
    }, 2000);
  }


  return (
    <div className="container-frontend">
      <div className="apply-now-btn" style={{textAlign:'center'}}>
      
        <button  className='essonova-btn'
        onClick={paymentportal}
        >Apply Now </button>
      </div>
      
      <section className="program-section">
        <div className="section-title">
          <div className="section-tag fw-bold">Program Schedule</div>
          <h3 className='gradient__text'>Ready to explore technology before others</h3>
        </div>
        <div className="container">
          <div className="row">
            {roadmapcard && roadmapcard.map((item, index) => (
              <RoadmapCard key={index} data={item} />
            ))}
          </div>
        </div>
      </section>

      <ProgramSchedule />
      

      <section className="technology-section">
        <div className="section-title">
          <div className="section-tag fw-bold mt-5">Technology Covered</div>
          <h3 className='gradient__text'>Stay Ahead by Exploring Cutting-Edge Technologies</h3>
        </div>

        <div className="row">
          {technologyDetails && technologyDetails.map((tech, index) => (
            <div className="col-md-3" key={index}>
              <div className="technology-card mt-3">
                <img className='Parant_component_logo' src={tech.logo} alt={`${tech.title} Logo`} />
                <h4>{tech.title}</h4>
                <p>{tech.body}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <div className="apply-now-btn" style={{textAlign:'center',marginTop:50}}>
        <button  className='essonova-btn'
         onClick={paymentportal}
         >Apply Now </button>
      </div>


      <section className="prerequisites-section">
        <h3 className='text-center section-tag fw-bold mt-5'>Prerequisites</h3>
        <h3 className='text-center gradient__text'>Get a preview of what you'll learn and apply to help you prepare</h3>
        <ul>
          {prerequisites && prerequisites.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>
    </div>
  );
}
