import React, { useEffect, useState } from 'react';
import Image from '../../assests/images/programs/Download_Syllabus.png';
import { RingLoader } from 'react-spinners';
import axios from 'axios';
const Syllabus = () => {

    const [requestsuccess, setRequestSuccess] = useState(null);
    const [requestfail, setRequestFail] = useState(null);

    const [loading, setLoading] = useState(false); //Loader State


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        program: '',
        consentChecked: false,
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        program: '',
        consentChecked: '',
    });

    const validateEmail = (value) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(value);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: newValue });
        setFormErrors({ ...formErrors, [name]: '' });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const newErrors = {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            program: '',
            consentChecked: '',
        };

        let isValid = true;

        if (!formData.firstName) {
            newErrors.firstName = 'Please enter your first name';
            isValid = false;
        }

        if (!formData.lastName) {
            newErrors.lastName = 'Please enter your last name';
            isValid = false;
        }

        if (!validateEmail(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
            isValid = false;
        }

        if (!formData.mobile || isNaN(formData.mobile)) {
            newErrors.mobile = 'Please enter a valid mobile number';
            isValid = false;
        }

        if (!formData.program) {
            newErrors.program = 'Please choose a program';
            isValid = false;
        }

        if (!formData.consentChecked) {
            newErrors.consentChecked = 'Please agree to the Terms and Conditions';
            isValid = false;
        }

        setFormErrors(newErrors);

        if (isValid) {
            senddata();
        }
    };

    const senddata = () => {
        const data = {
            fname: formData.firstName,
            lname: formData.lastName,
            email: formData.email,
            phone: formData.mobile,
            program: formData.program,
        };

        setLoading(true); //Loaser State
        axios.post(`${process.env.REACT_APP_API_URL}/SyllabusEnquiry`, data)
            .then((response) => {
                setLoading(false); //Loaser State
                setRequestFail("Download Error");
                setRequestSuccess(null);
            })
            .catch((error) => {
                setLoading(false); //Loaser State
                setRequestFail("Technical Issue");
                setRequestSuccess(null);
                console.error(error);
            });
    }

    // useEffect to make sure the request states are reset when we come back to this form
    useEffect(() => {
        setRequestFail(null);
        setRequestSuccess(null);
    },[]);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6 col-md-8 col-sm-10 col-xs-12'>
                    <div className="section-tag fw-bold text-center">Syllabus</div>
                    <h3 className='gradient__text text-center'>Download the syllabus</h3>
                    <div className='subtitle mb-3'>
                        Get a preview of our Program and learn why aspiring developers choose e-Neuratechlab.
                    </div>

                    {loading ? (
                        <RingLoader
                            color="#21AEA7"
                            loading={loading}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    ) : (
                        <form onSubmit={handleFormSubmit}>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    className={`form-control ${formErrors.firstName && 'is-invalid'}`}
                                    placeholder='First Name'
                                    name='firstName'
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                />
                                {formErrors.firstName && (
                                    <div className='invalid-feedback'>{formErrors.firstName}</div>
                                )}
                            </div>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    className={`form-control ${formErrors.lastName && 'is-invalid'}`}
                                    placeholder='Last Name'
                                    name='lastName'
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                />
                                {formErrors.lastName && (
                                    <div className='invalid-feedback'>{formErrors.lastName}</div>
                                )}
                            </div>
                            <div className='form-group'>
                                <input
                                    type='email'
                                    className={`form-control ${formErrors.email && 'is-invalid'}`}
                                    placeholder='E-mail'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                                {formErrors.email && (
                                    <div className='invalid-feedback'>{formErrors.email}</div>
                                )}
                            </div>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    className={`form-control ${formErrors.mobile && 'is-invalid'}`}
                                    placeholder='Mobile'
                                    name='mobile'
                                    value={formData.mobile}
                                    onChange={handleInputChange}
                                />
                                {formErrors.mobile && (
                                    <div className='invalid-feedback'>{formErrors.mobile}</div>
                                )}
                            </div>
                            <div className='form-group'>
                                <select
                                    className={`form-control ${formErrors.program && 'is-invalid'}`}
                                    name='program'
                                    value={formData.program}
                                    onChange={handleInputChange}
                                >
                                    <option disabled value=''>Choose Program</option>
                                    <option value='Basics of software development'>Basics of software development</option>
                                    <option value='Frontend Development'>Frontend Development</option>
                                    <option value='Backend Development'>Backend Development</option>
                                    <option value='Full Stack Development'>Full Stack Development</option>
                                </select>
                                {formErrors.program && (
                                    <div className='invalid-feedback'>{formErrors.program}</div>
                                )}
                            </div>
                            <div className='form-check'>
                                <input
                                    type='checkbox'
                                    className={`form-check-input ${formErrors.consentChecked && 'is-invalid'}`}
                                    id='consentCheckbox'
                                    name='consentChecked'
                                    checked={formData.consentChecked}
                                    onChange={handleInputChange}
                                />
                                <label className='form-check-label term_condition' htmlFor='consentCheckbox'>
                                    I agree to your <span className='terms-and-conditions'><b>Terms and Conditions</b></span>.
                                </label>
                                {formErrors.consentChecked && (
                                    <div className='invalid-feedback'>{formErrors.consentChecked}</div>
                                )}
                            </div>
                            <button type='submit' className='essonova-btn'>
                                Download Syllabus
                            </button>
                        </form>
                    )}

                    {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
                    {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}
                </div>
                <div className='col-lg-6 col-md-4 col-sm-2 col-xs-12'>
                    <img className='img-fluid syllabus-image' src={Image} alt='loading...' />
                </div>
            </div>
        </div>
    );
};

export default Syllabus;
