import React, { useContext, useState } from 'react'
import { StateContext } from '../StateManager/StateManager';

const ContactRequests = () => {

const statemanager = useContext(StateContext);
const [searchInput, setSearchInput] = useState('');

const [currentpageno, setCurrentPageNo] = useState(1);

const dataperpage = 10;

let start_index = currentpageno === 1 ? 0 : (currentpageno * dataperpage - dataperpage);
let end_index = currentpageno === 1 ? 10 : (currentpageno * dataperpage);

const data = statemanager.data_from_db.contactRequestsData.state;

const totalpages = Math.ceil((data.length) / dataperpage);
let currentdata = data.slice(start_index, end_index);

const filteredRequest = currentdata.filter((item) => {
  return (
    (searchInput === '' || ((item.name).toLowerCase().includes(searchInput.toLowerCase())))
  );
});


return (
  <div className='admin-blogs-manage-container'>
    <div className="admin-blogs-manage-container__table">
      <table >
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Subject</th>
            <th>Message</th>
            <th>
              <input
                type='text'
                name='search'
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder='name'
                className='admin-blog-search' />
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredRequest.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.phone}</td>
              <td>{item.subject}</td>
              <td>{item.message}</td>
              {/* <td>
                <div className='edit-button'>
                  <button className="btn btn-primary"
                    onClick={() => {
                      statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false);
                      statemanager.adminstates.adminbuttons.edit_blog_current_item.setState(item);
                      statemanager.adminstates.adminbuttons.add_edit_blog.setState(true);

                    }}>
                    Edit</button>
                </div>
              </td>
              <td>
                <div className='delete-button'>
                  <button className="btn btn-danger"
                    onClick={() => {
                      statemanager.adminstates.adminbuttons.add_edit_blog.setState(false);
                      statemanager.adminstates.adminbuttons.delete_button.delete_component_current_data.setState(item);
                      statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(true);
                    }}>
                    Delete</button>
                </div>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="admin-usermanage-container__page-numbers">
      <div className="prev-page-button" onClick={() => {
        currentpageno === 1 ? setCurrentPageNo(1) : setCurrentPageNo(currentpageno - 1)
      }}>Prev</div>
      <div className="current-page-number">{currentpageno}&nbsp;/&nbsp;{totalpages}</div>

      <div className="next-page-button" onClick={() => {
        currentpageno === totalpages ? setCurrentPageNo(totalpages) : setCurrentPageNo(currentpageno + 1)
      }}>Next</div>
    </div>


  </div>
)
}

export default ContactRequests;
