import React from 'react'
import Banner from './components/Banner/Banner'
import Benifits from './components/Benifits/Benifits'
import Faq from './components/Faq/Faq'
import Timeline from './components/Roadmap/Roadmap'
import Introduction from './components/Introduction/Introduction'
// import Testimonial from './components/Testimonial/Testimonial'
import Partner from './components/OurPartner/Partner'
import InstructorSection from './components/Instructor/Instructor'
import QuoteImage from './components/Quotes/Quotes'
import { faq_home } from './context/context'
import CourseSlider from './components/CourseSlider/CourseSlider'
import ISAComponent from './components/IncomeSharingAgreemnet/Isa'
import EssonovaBanner from './components/EssonovaBanner/Essonovabanner'
import TestimonialSlider from './components/Studentsay/Sliderstudentsay'

const Layout = () => {
    return (
        <>
            <Banner />
            <EssonovaBanner/>
            <Benifits />
            <Introduction />
            <CourseSlider/>
            <QuoteImage/>
            <InstructorSection />
            <Timeline />
            <Faq data = {faq_home}/>
            <TestimonialSlider/>
            {/* <Testimonial /> */}
            <Partner/>
            <ISAComponent/>

        </>
    )
}

export default Layout


// new coommnetnt