import React, { useContext, useState, useEffect } from 'react'
import axios from 'axios';
import './admin_css/adminpanel.css'
import { StateContext } from '../StateManager/StateManager'
import UsersManage from './UsersManage';
import CourseManage from './CourseManage';
import ContactRequests from './ContactRequests';
import BlogsManage from './BlogsManage';
import EditInfo from './EditInfo';
import DeleteUser from './DeleteUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers, faBook, faBlog, faEnvelope, faPlus } from '@fortawesome/free-solid-svg-icons';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import AddBlog from './AddBlog';
import AdminHome from './AdminHome';
import Subscribed from './Subscribed';
import { useMemo } from 'react';




const AdminPanel = () => {
    const statemanager = useContext(StateContext);
    const [toggleMenu, setToggleMenu] = useState(false);

    const loginData = JSON.parse(sessionStorage.getItem('loginData'));
    const token = loginData.token;

    const config = useMemo(() => ({
        headers: {
            Authorization: `Bearer ${token}`
        }
    }), [token]);
    // the useMemo hook ensure that the config object is only recreated when token changes:
    // using it with useEffect dependency will make useEffect run unnecessarily
    
    useEffect(() => {
     
        axios.get(`${process.env.REACT_APP_API_URL}/User`, config)
            .then((result) => { statemanager.data_from_db.userdata.setState(result.data); })
            .catch((error) => { //console.log(error);
             });

        axios.get(`${process.env.REACT_APP_API_URL}/Program`,config)
            .then((result) => { statemanager.data_from_db.coursedata.setState(result.data); })
            .catch((error) => { //console.log(error);
             });

        axios.get(`${process.env.REACT_APP_API_URL}/Blog`,config)
            .then((result) => { statemanager.data_from_db.blogdata.setState(result.data); })
            .catch((error) => { //console.log(error);
             });

        axios.get(`${process.env.REACT_APP_API_URL}/Contact`,config)
            .then((result) => { statemanager.data_from_db.contactRequestsData.setState(result.data); })
            .catch((error) => { //console.log(error);
             });

        axios.get(`${process.env.REACT_APP_API_URL}/Subscription`,config)
            .then((result) => { statemanager.data_from_db.subscribeData.setState(result.data); })
            .catch((error) => { //console.log(error);
             });
    }, 
    // eslint-disable-next-line
    []);
    //config,statemanager.data_from_db.blogdata, statemanager.data_from_db.contactRequestsData, statemanager.data_from_db.coursedata, statemanager.data_from_db.subscribeData,statemanager.data_from_db.userdata

    return (
        <div className='admin-panel-container'>

            {/* //
            //  add_blog displayed here to show on
             full screen including the menu bar
            // */}
            {statemanager.adminstates.adminbuttons.add_edit_blog.state && <AddBlog />}
            <div className="admin-panel-container__sidebar__small-menu">
                {toggleMenu
                    ? <RiCloseLine color="#3F1179" size={27} onClick={() => setToggleMenu(false)} />
                    : <RiMenu3Line color="#3F1179" size={27} onClick={() => setToggleMenu(true)} />}
                {toggleMenu && (
                    <div className='admin-panel-container__sidebar__small-menu__items'>
                        <div className='admin-panel-container__sidebar__heading'>Admin Dashboard</div>
                        <hr className='admin-panel-container-divider'></hr>
                        <li onClick={() => {
                            statemanager.adminstates.adminhome.setState(true);
                            statemanager.adminstates.adminusers.setState(false);
                            statemanager.adminstates.admincourses.setState(false);
                            statemanager.adminstates.admincontactrequests.setState(false);
                            statemanager.adminstates.adminblogs.setState(false);
                            statemanager.adminstates.adminsubscribed.setState(false);
                        }}>
                            <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faHome} /></span>
                            Home</li>

                        <li onClick={() => {
                            statemanager.adminstates.adminhome.setState(false);
                            statemanager.adminstates.adminusers.setState(true);
                            statemanager.adminstates.admincourses.setState(false);
                            statemanager.adminstates.admincontactrequests.setState(false);
                            statemanager.adminstates.adminblogs.setState(false);
                            statemanager.adminstates.adminsubscribed.setState(false);
                        }}>
                            <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faUsers} /></span>
                            Users</li>

                        <li onClick={() => {
                            statemanager.adminstates.adminhome.setState(false);
                            statemanager.adminstates.adminusers.setState(false);
                            statemanager.adminstates.admincourses.setState(true);
                            statemanager.adminstates.admincontactrequests.setState(false);
                            statemanager.adminstates.adminblogs.setState(false);
                            statemanager.adminstates.adminsubscribed.setState(false);
                        }}>
                            <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faBook} /></span>
                            Programs</li>

                        <li onClick={() => {
                            statemanager.adminstates.adminhome.setState(false);
                            statemanager.adminstates.adminusers.setState(false);
                            statemanager.adminstates.admincourses.setState(false);
                            statemanager.adminstates.admincontactrequests.setState(true);
                            statemanager.adminstates.adminblogs.setState(false);
                            statemanager.adminstates.adminsubscribed.setState(false);
                        }}>
                            <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faEnvelope} /></span>
                            Contact Request</li>

                        <li onClick={() => {
                            statemanager.adminstates.adminhome.setState(false);
                            statemanager.adminstates.adminusers.setState(false);
                            statemanager.adminstates.admincourses.setState(false);
                            statemanager.adminstates.admincontactrequests.setState(false);
                            statemanager.adminstates.adminblogs.setState(true);
                            statemanager.adminstates.adminsubscribed.setState(false);
                        }}>
                            <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faBlog} /></span>
                            Blogs</li>

                        <li onClick={() => {
                            statemanager.adminstates.adminhome.setState(false);
                            statemanager.adminstates.adminusers.setState(false);
                            statemanager.adminstates.admincourses.setState(false);
                            statemanager.adminstates.admincontactrequests.setState(false);
                            statemanager.adminstates.adminblogs.setState(false);
                            statemanager.adminstates.adminsubscribed.setState(true);

                        }}>
                            <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faPlus} /></span>
                            Subscription</li>
                    </div>


                )}
            </div>



            <div className="admin-panel-container__sidebar">
                <div className='admin-panel-container__sidebar__heading'>Admin Dashboard</div>
                <hr className='admin-panel-container-divider'></hr>
                <li onClick={() => {
                    statemanager.adminstates.adminhome.setState(true);
                    statemanager.adminstates.adminusers.setState(false);
                    statemanager.adminstates.admincourses.setState(false);
                    statemanager.adminstates.admincontactrequests.setState(false);
                    statemanager.adminstates.adminblogs.setState(false);
                    statemanager.adminstates.adminsubscribed.setState(false);
                }}>
                    <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faHome} /></span>
                    Home</li>

                <li onClick={() => {
                    statemanager.adminstates.adminhome.setState(false);
                    statemanager.adminstates.adminusers.setState(true);
                    statemanager.adminstates.admincourses.setState(false);
                    statemanager.adminstates.admincontactrequests.setState(false);
                    statemanager.adminstates.adminblogs.setState(false);
                    statemanager.adminstates.adminsubscribed.setState(false);
                    //getData();

                }}>
                    <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faUsers} /></span>
                    Users</li>

                <li onClick={() => {
                    statemanager.adminstates.adminhome.setState(false);
                    statemanager.adminstates.adminusers.setState(false);
                    statemanager.adminstates.admincourses.setState(true);
                    statemanager.adminstates.admincontactrequests.setState(false);
                    statemanager.adminstates.adminblogs.setState(false);
                    statemanager.adminstates.adminsubscribed.setState(false);
                }}>
                    <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faBook} /></span>
                    Programs</li>

                <li onClick={() => {
                    statemanager.adminstates.adminhome.setState(false);
                    statemanager.adminstates.adminusers.setState(false);
                    statemanager.adminstates.admincourses.setState(false);
                    statemanager.adminstates.admincontactrequests.setState(true);
                    statemanager.adminstates.adminblogs.setState(false);
                    statemanager.adminstates.adminsubscribed.setState(false);
                }}>
                    <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faEnvelope} /></span>
                    Contact Request</li>

                <li onClick={() => {
                    statemanager.adminstates.adminhome.setState(false);
                    statemanager.adminstates.adminusers.setState(false);
                    statemanager.adminstates.admincourses.setState(false);
                    statemanager.adminstates.admincontactrequests.setState(false);
                    statemanager.adminstates.adminblogs.setState(true);
                    statemanager.adminstates.adminsubscribed.setState(false);
                }}>
                    <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faBlog} /></span>
                    Blogs</li>

                <li onClick={() => {
                    statemanager.adminstates.adminhome.setState(false);
                    statemanager.adminstates.adminusers.setState(false);
                    statemanager.adminstates.admincourses.setState(false);
                    statemanager.adminstates.admincontactrequests.setState(false);
                    statemanager.adminstates.adminblogs.setState(false);
                    statemanager.adminstates.adminsubscribed.setState(true);

                }}>
                    <span className='admin-panel-container__sidebar__icon'><FontAwesomeIcon icon={faPlus} /></span>
                    Subscription</li>

            </div>
            <div className="admin-panel-container__content-area">

                {statemanager.adminstates.adminhome.state && <AdminHome />}
                {statemanager.adminstates.adminbuttons.edit_user.state && <EditInfo />}
                {statemanager.adminstates.adminbuttons.delete_button.delete_component_show.state && <DeleteUser />}
                {statemanager.adminstates.adminusers.state && <UsersManage />}
                {statemanager.adminstates.admincourses.state && <CourseManage />}
                {statemanager.adminstates.admincontactrequests.state && <ContactRequests />}
                {statemanager.adminstates.adminblogs.state && <BlogsManage />}
                {statemanager.adminstates.adminsubscribed.state && <Subscribed />}

            </div>


        </div>
    )
}

export default AdminPanel