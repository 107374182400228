import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { StateContext } from '../../../components/StateManager/StateManager';
import { RingLoader } from 'react-spinners';


const Registration = () => {
  const [requestsuccess, setRequestSuccess] = useState(null);
  const [requestfail, setRequestFail] = useState(null);

  const [loading, setLoading] = useState(false);//Loader State

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [country, setCountry] = useState('');
  //const [location_state, setLocationState] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [mobile, setMobile] = useState('');
  //const [porgram_enrolled, setProgramEnrolled] = useState('');
  //const [showcourses, setShowCourses] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const statemanager = useContext(StateContext);

  const validateForm = () => {
    const errors = [];

    if (!fname) {
      errors.push('First Name is required');
    }

    if (!lname) {
      errors.push('Last Name is required');
    }

    if (!country) {
      errors.push('Country is required');
    }

    // if (!location_state) {
    //   errors.push('State is required');
    // }

    if (!username) {
      errors.push('Username is required');
    }

    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      errors.push('Enter a valid Email address');
    }

    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long');
    }

    if (password !== confirm_password) {
      errors.push('Passwords do not match');
    }

    if (!mobile) {
      errors.push('Mobile number is required');
    }

    // if (!porgram_enrolled) {
    //   errors.push('Program Enrolled is required');
    // }

    if (!consentChecked) {
      errors.push('Please agree to the Terms and Conditions');
    }

    setErrorMessages(errors);

    return errors.length === 0;
  };

  const postData = () => {
    const isValid = validateForm();

    if (isValid) {
      let register_data = {
        Fname: fname,
        Lname: lname,
        Email: email,
        Phone: mobile,
        Country: country,
        State: 'NULL',
        Program_Name: "Null",
        Username: username,
        Password: password,
        Image: "NA",
        Position: "user",
        User_Id: "0001",
        reg_date: new Date().toISOString(),
        active: 1
      };

      setLoading(true); //Loader State
      
      axios
        .post(`${process.env.REACT_APP_API_URL}/User/register`, register_data)
        .then((response) => {
          setRequestFail(null);
          setRequestSuccess("Registratoin Successfull");
          setLoading(false);  //Loader State
          setTimeout(() => {
            statemanager.registerform.setState(false);
            setRequestSuccess(null);
          }, 2000);
        })
        .catch((error) => {
          //console.log(error);
          //error.data ? setRequestFail(error.response.data.message) : setRequestFail(error.message)
          setRequestFail("Failed Registration");
          setRequestSuccess(null);
          setLoading(false);  //Loader State
        });
    }
    else {
      //console.log('Form data is not valid. Please correct the errors.');
    }
  };

  return (
    <section className="register-essonova">
      <div className="container-register">
        <div className="register-button-div">
          <button className="section-tag" onClick={() => {
            setRequestSuccess(null);
            setRequestFail(null);
            statemanager.registerform.setState(false);

          }}> X </button>
        </div>
        <div className="section-title text-center wow fadeInDown">
          <div className="section-tag fw-bold"></div>
          <h3 className='gradient__text'>First, let’s get some basic info.</h3>
        </div>
        <div className="register-form wow fadeInUp">

          {loading ? (
            <RingLoader
              color="#21AEA7"
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <form>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="First Name" value={fname} onChange={(e) => setFname(e.target.value)} required />
                    {errorMessages.includes('First Name is required') && <p className="error-message" style={{ color: 'red' }}>First Name is required</p>}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Last Name" value={lname} onChange={(e) => setLname(e.target.value)} required />
                    {errorMessages.includes('Last Name is required') && <p className="error-message" style={{ color: 'red' }}>Last Name is required</p>}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <select className="dropdown_btn" value={country} onChange={(e) => setCountry(e.target.value)} required>
                      <option value="">Choose Country</option>
                      <option> United States</option>
                      <option> Canada</option>
                      <option> United Kingdom</option>
                      <option> France</option>
                      <option> Germany</option>
                      <option> Japan</option>
                      <option> Australia</option>
                      <option> China</option>
                      <option> India</option>
                      <option> Brazil</option>
                      <option> Mexico</option>
                      <option> South Africa</option>
                      <option> Egypt</option>
                      <option> Russia</option>
                      <option> South Korea</option>
                    </select>
                    {errorMessages.includes('Country is required') && <p className="error-message" style={{ color: 'red' }}>Country is required</p>}
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="State" value={location_state} onChange={(e) => setLocationState(e.target.value)} required />
                  {errorMessages.includes('State is required') && <p className="error-message" style={{ color: 'red' }}>State is required</p>}
                </div>
              </div> */}

                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <input type="Username" className="form-control" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    {errorMessages.includes('Username is required') && <p className="error-message" style={{ color: 'red' }}>Username is required</p>}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    {errorMessages.includes('Enter a valid Email address') && <p className="error-message" style={{ color: 'red' }}>Enter a valid Email address</p>}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} required />
                    {errorMessages.includes('Mobile number is required') && <p className="error-message" style={{ color: 'red' }}>Mobile number is required</p>}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    {errorMessages.includes('Password must be at least 8 characters long') && <p className="error-message" style={{ color: 'red' }}>Password must be at least 8 characters long</p>}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <input type="password" className="form-control" placeholder="Confirm Password" value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} required />
                    {errorMessages.includes('Passwords do not match') && <p className="error-message" style={{ color: 'red' }}>Passwords do not match</p>}
                  </div>
                </div>


                {/* <div className="col-lg-6 col-md-6 col-12">
                <div className="form-group">
                  <div className="register-page-dropdown">
                    <select className="dropdown_btn"
                      value={porgram_enrolled} onChange={(e) => {
                        setProgramEnrolled(e.target.value);
                        setShowCourses(!showcourses);
                      }} >
                      <option value="" selected>Choose Program</option>
                      <option value="Basics of software development">Basics of software development</option>
                      <option value="Frontend Development">Frontend Development</option>
                      <option value="Backend Development">Backend Development</option>
                      <option value="Full Stack Development">Full Stack Development</option>
                    </select>
                    {errorMessages.includes('Program Enrolled is required') && <p className="error-message" style={{ color: 'red' }}>Program Enrolled is required</p>}
                  </div>
                </div>
              </div> */}

                <div className="col-lg-12 col-md-12 col-12">
                  <div className="form-check mb-3">
                    <input type="checkbox" className="form-check-input" id="consentCheckbox" checked={consentChecked} onChange={() => setConsentChecked(!consentChecked)} />
                    <label htmlFor="consentCheckbox">I agree to your <Link to="/termsandconditions"><span className='terms-and-conditions'><b>Terms and Conditions</b></span></Link>.</label>
                    {errorMessages.includes('Please agree to the Terms and Conditions') && <p className="error-message" style={{ color: 'red' }}>Please agree to the Terms and Conditions</p>}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <button type="button" className="essonova-btn" onClick={postData}>Join Us!</button>
                </div>
                {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
                {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}

              </div>
            </form>
          )}

        </div>
      </div>
    </section>
  );
};

export default Registration;
