import React, { useContext, useState } from 'react'
import { StateContext } from '../StateManager/StateManager';
import './admin_css/edit_info.css'
import axios from 'axios';
import RingLoader from "react-spinners/RingLoader";



const EditInfo = () => {

    const [requestsuccess, setRequestSuccess] = useState(null);
    const [requestfail, setRequestFail] = useState(null);

    const [loading, setLoading] = useState(false);

    const statemanager = useContext(StateContext);
    const item = statemanager.adminstates.adminbuttons.edit_user_current_item.state;
    const [editedUserData, setEditedUserData] = useState(item);




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUserData({ ...editedUserData, [name]: value });
    };

    const updateData = () => {
        setLoading(true);//Loader State
        axios.put(`${process.env.REACT_APP_API_URL}/User`, editedUserData)
            .then(response => {
                setLoading(false);//Loader State
                setRequestFail(null);
                setRequestSuccess("Information Updated Successfully");
            })
            .catch(error => {
                setLoading(false);//Loader State
                //console.log(error);
                setRequestFail("Failed");
                setRequestSuccess(null);
            });
    }
    return (
        <div className="edit-info-container">
            <table className="edit-info-container__table">
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>SNo.</td>
                        <td>{editedUserData.id}</td>
                    </tr>
                    <tr>
                        <td>Fname</td>
                        <td>
                            <input
                                className='edit-info-container__table__td__input'
                                type="text"
                                name="fname"
                                value={editedUserData.fname}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Lname</td>
                        <td>
                            <input
                                className='edit-info-container__table__td__input'
                                type="text"
                                name="lname"
                                value={editedUserData.lname}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Username</td>
                        <td>
                            <input
                                className='edit-info-container__table__td__input'
                                type="text"
                                name="username"
                                value={editedUserData.username}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>
                            <input
                                className='edit-info-container__table__td__input'
                                type="text"
                                name="email"
                                value={editedUserData.email}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td>
                            <input
                                className='edit-info-container__table__td__input'
                                type="text"
                                name="phone"
                                value={editedUserData.phone}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Program Name</td>
                        <td>
                            <input
                                className='edit-info-container__table__td__input'
                                type="text"
                                name="programName"
                                value={editedUserData.program_name}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>

                    {loading ? (
                            <RingLoader
                                color="#21AEA7"
                                loading={loading}
                                size={150}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        ) : (
                            <tr>
                        <td >
                            <div className="edit-info-container__table__submit-button"
                                onClick={updateData}>Submit</div>
                        </td>
                        <td>
                            <div className="edit-info-container__table__cancel-button"
                                onClick={() => {
                                    setRequestFail(null);
                                    setRequestSuccess(null);
                                    statemanager.adminstates.adminbuttons.edit_user.setState(false)
                                }}>
                                Cancel
                            </div>
                        </td>
                    </tr>
                        )}
                    
                </tbody>
            </table >
            {requestsuccess && <p className='alert-message' style={{ color: 'green', }}>{requestsuccess}</p>}
            {requestfail && <p className='alert-message' style={{ color: 'red' }}>{requestfail}</p>}
        </div>
    );
}

export default EditInfo
