import React from "react";
import sanjay from '../../assests/images/Instructor/Sanjay Sir.png'
import walter from '../../assests/images/Instructor/Walter.png'

const AboutUs = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <section>
        <div className="page_heading about_heading">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-tag text-center fw-bold">Explore Us</div>
                <h3 className="gradient__text text-center">Welcome to Essonova Neurotechlabs, here's a little about our company!</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="section-title">
                  <div className="section-tag">Our Founder</div>
                </div>
                <p>Our founder, Mr. Sanjay Kumar, leads Essonova's Digital Learning and Training Program. He holds a Masters in Systems & Information from BITS, Pilani, India, Masters in Statistics from Institute of Advanced Studies, India, and a Bachelors in Computer Science from Delhi University, India.</p>
                <p>Mr. Sanjay Kumar is currently serving as Principal and Chief Architect, overseeing architecture, systems design, development, and systems integration for our IT consulting projects and industry-specific framework and software product development.</p>
                <p>With over 17 years of experience, Mr. Sanjay Kumar has a rich background in architecture, design, development, systems integration, and management of complex software applications and products.</p>
              </div>

              <div className="col-md-6">
                <div className="section-title">
                  <h4 className="gradient__text text-center"><b>Sanjay Kumar</b></h4>
                </div>
                <div className="text-center">
                  <img
                    className="rounded-circle"
                    src={sanjay}
                    alt="Sanjay Kumar"
                    style={{ width: '200px', height: '200px' }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="section-title">
                  <h4 className="gradient__text text-center"><b>Walter H. Contreras</b></h4>
                </div>
                <div className="text-center">
                  <img
                    className="rounded-circle"
                    src={walter}
                    alt="Walter"
                    style={{ width: '200px', height: '200px' }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="section-title">
                  <div className="section-tag">Our CEO</div>
                </div>
                <p>Our CEO, Mr. Walter H. Contreras, has over 15 years of experience in Application and System programming, Software Technologies, and Operative systems. Providing mainly consulting services in Custom Applications, Architectural Design, Performance, and Best Practices.</p>
                <p>He has worked as a Software Architect for complex projects in Fortune 500 companies, providing consulting, support, training, software design services, advanced debugging, and troubleshooting, among other expertise.</p>
                <p>With a diverse skill set and extensive experience in various technologies, Mr. Contreras brings a wealth of knowledge to our organization.</p>
              </div>
              <div className="col-md-12">
                <div className="section-title">
                  <div className="section-tag">About Essonova Neurotechlab's Training Program</div>
                </div>
                <p><b>Essonova Neurotechlabs Digital Learning and training programs</b> offers cutting-edge training in Information Technology. Our program provides a wide array of innovative solutions and services in IT Consultancy, Software Development, Staffing, Data Analytics, Product Development, Application Framework Development, Maintenance, Tier-3 Support, OS Level Debugging, and Reverse Engineering.</p>
                <p>At E-neurotechlabs, we believe in the continuous improvement of business processes through IT innovation. Our proven expertise and experience in enterprise and mobile application development make us leaders in the IT industry.</p>
                <p>We focus on the convergence of business experience, strategy, and technology to design and deploy high-impact business and engineering solutions.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="nuratech-story">
        <div className="container">
          <h2 className="gradient__text text-center">About E-neurotechlabs</h2>
          <p>E-neurotechlabs was founded by Mr. Sanjay Kumar and Walter, a former Microsoft employee. After spending 25 years leading the development of digital learning at Microsoft, Walter experienced firsthand the lack of diversity in software development careers.</p>
          <p>He thought software training might be the answer, but quickly discovered significant affordability and accessibility gaps, especially for those without college degrees, lacking previous job experience in technology, or underemployed even while working full-time.</p>
          <p>So, he left Microsoft and started E-neurotechlabs to revolutionize how people access rewarding careers in software development, without requiring a college degree or expensive loans in the form of income sharing agreements.</p>
          <p>Many aspiring developers with heart and potential feel counted out. E-neurotechlabs opened its first training stated in AVENUE, SUITE 262 PLANO, TX and India met many aspiring developers showing heart and potential, but who felt left out of the digital economy just because of where they lived or economic status.</p>
          <p>This felt incredibly unfair, so he committed to building E-neurotechlabs into a superior international training experience with unmatched value.</p>
          <p>E-neurotechlabs then expanded the team with exceptional individuals with a shared passion to help others...</p>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
