import axios from 'axios';
import React, { useContext, useState } from 'react'
import { StateContext } from '../../../components/StateManager/StateManager';
import RingLoader from "react-spinners/RingLoader";


const ForgetPassword = ({ onClose }) => {
    const statemanager = useContext(StateContext);

    let [loading, setLoading] = useState(false);

    const [requestsuccess, setRequestSuccess] = useState(null);
    const [requestfail, setRequestFail] = useState(null);
    const [validemail, setValidEmail] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [genotphash, setGenOtpHash] = useState(null);
    const [userid, setUserId] = useState();


    const [email, setEmail] = useState("")
    const [otpSent, setOtpSent] = useState(false)
    const [otp, setOtp] = useState("")

    const sendOtp = () => {
        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setEmailError(true);
        }
        else {
            setEmailError(false);
            setValidEmail(true);
        }

        if (validemail) {
            setLoading(true);//Loader state

            const data = { email: email }


            axios
                .post(`${process.env.REACT_APP_API_URL}/PasswordForgot/verify-email`, data)
                .then((response) => {
                    setLoading(false);//Loader state //place inside promise because placing it outside will make it execute before promise execution ends
                    setGenOtpHash(response.data.genotphash); // getting the hashcode of otp from server
                    setUserId(response.data.id); // getting the user id according to the email
                    setOtpSent(true);
                    setRequestFail(null);
                    setRequestSuccess(response.data.message);
                    setTimeout(() => {
                        setRequestSuccess(null);
                    }, 2000);
                })
                .catch((error) => {
                    setLoading(false);//Loader state
                    //console.log(error);
                    error.data ? setRequestFail(error.response.data.message) : setRequestFail(error.message)
                    setRequestSuccess(null);
                });

        }

    }

    const verifyOtp = () => {

        setLoading(true);  //Loader state
        const data = {
            generatedotphash: genotphash,
            userenteredotphash: otp,
            id: userid
        }

        axios
            .post(`${process.env.REACT_APP_API_URL}/PasswordForgot/verify-otp`, data)
            .then((response) => {
                setLoading(false);  //Loader state
                setRequestFail(null);
                setRequestSuccess(response.data.message);
                //console.log(response.data);
                sessionStorage.setItem('response_emailverification', JSON.stringify(response.data));
                statemanager.changePassword.setState(true);
                statemanager.forgetpassword.setState(false);
            })
            .catch((error) => {
                setLoading(false);  //Loader state
                //console.log(error);
                error.data ? setRequestFail(error.response.data.message) : setRequestFail(error.message)
                setRequestSuccess(null);
            });
    }
    return (

        < >
            <section className="forget_password-essonova">
                <div className="container_forget_password">
                    <div className="forget_password-close-div">
                        <button className="section-tag" onClick={() => onClose(false)}> X </button>
                    </div>
                    <div className="section-title text-center wow fadeInDown">
                        <div className="section-tag fw-bold"></div>
                        <h3 className='gradient__text'>Forgot Password</h3>
                    </div>
                    <div className={loading?"forget_password-form wow fadeInUp essonova-loader":"forget_password-form wow fadeInUp"}>

                        {loading ? (
                            <RingLoader
                                color="#21AEA7"
                                loading={loading}
                                size={150}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        ) : (

                            <form>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6 col-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Registered Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                        {emailError && <div className="error-message" style={{ color: 'red' }}>Email format invalid</div>}

                                    </div>
                                    {otpSent &&
                                        <div className="col-lg-12 col-md-6 col-12">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                                            </div>
                                        </div>}
                                    {!otpSent ?
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <button type="button" onClick={sendOtp} className="essonova-btn">Send OTP</button>
                                        </div> :
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <button type="button" onClick={verifyOtp} className="essonova-btn">Verify OTP</button>
                                        </div>}
                                </div>

                            </form>
                        )}
                        {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
                        {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgetPassword;
