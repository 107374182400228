import React, { useState } from 'react';
import bootcampData from '../../context/context'; // Adjust the path to your file

const ISAComponent = () => {
    const [selectedBootcamp, setSelectedBootcamp] = useState(bootcampData.bootcamps[0]);

    const handleBootcampChange = (bootcampId) => {
        const bootcamp = bootcampData.bootcamps.find((b) => b.id === bootcampId);
        setSelectedBootcamp(bootcamp);
    };

    return (
        <>
            <div className="section-title mt-3 container">
                <div className="section-tag fw-bold">Income Sharing Agreement (ISA)</div>
                <h3 className='gradient__text'>Your ISA, including terms, payment structure, eligibility criteria, and more.</h3>
            </div>

            <div className="isa-container">
                <div>
                    <select onChange={(e) => handleBootcampChange(parseInt(e.target.value))}>
                        {bootcampData.bootcamps.map((bootcamp) => (
                            <option key={bootcamp.id} value={bootcamp.id}>
                                {bootcamp.name}
                            </option>
                        ))}
                    </select>
                </div>
                <h2 className='mt-2 fw-bold section-tag'>{selectedBootcamp.name}</h2>
                <p>{selectedBootcamp.termsAndConditions}</p>
                <p>Duration: {selectedBootcamp.duration}</p>
                <p>Payment Structure: {selectedBootcamp.paymentStructure}</p>
                <p>Eligibility Criteria: {selectedBootcamp.eligibilityCriteria}</p>

            </div>
        </>
    );
};

export default ISAComponent;
