import React from 'react';
import about from '../../assests/images/banner-esso.jpg';
import '../../assests/css/style.css';
import { HashLink } from 'react-router-hash-link';


const Banner = () => {
    return (
        
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="hero-content">
                        <div className="text">
                            <h2 className="animated-heading gradient__text">
                                Launch Your Career in Technology
                            </h2>
                            <h3 className="animated-subheading">Become a Coding Expert with the Most Affordable Software Development Bootcamps</h3>
                            <h4 className="animated-subsubheading">Become a Software Engineer through Our Top-notch Curriculum, World-class Top-rated Instructors, Affordable Tuition Fees, and Flexible Schedule.</h4>
                            <div className="banner-button-container">
                                <p><HashLink to="#bootcamps" className="essonova-btn">View Our Bootcamps</HashLink></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="image">
                        <img src={about} alt="essonova neura tech lab" className="animated-image" />
                    </div>
                </div>
            </div>
        </div>
        
    );
};
export default Banner;