import React, { useState } from 'react';
import '../../assests/css/style.css'; // Include your existing CSS file

import basicImage from '../../assests/images/programs/fend.png';
import beginnersImage from '../../assests/images/programs/frontend.png';
import intermediateImage from '../../assests/images/programs/ai.png';
import advancedImage from '../../assests/images/programs/fstack.png';

const steps = [
  { image: basicImage, description: 'Web Development Fundamentals' },
  { image: beginnersImage, description: 'Frontend & Backend Development' },
  { image: intermediateImage, description: 'Full Stack Development' },
  { image: advancedImage, description: 'Hunting for Project/Job' },
];

const Roadmap = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  return (
    <div className="container mt-5">
      <div className="section-tag fw-bold text-center">Learning Roadmap</div>
      <div className='title-holder'>
        <h3 className='gradient__text'>The most extensive education for a technology career</h3>
      </div>
      <div className='subtitle mb-3'>
        Get a roadmap to make it easy to start today and finish strong.
        The comprehensive software development training program provides the best blend offered by E-NeuraTechLab in the realm of technology, preparing you for real-world projects.
      </div>
      <div className="timeline-path">
        {steps.map((step, index) => {
          const { image, description } = step;
          const isActive = index === currentStep;
          const isCompleted = index < currentStep;

          return (
            <React.Fragment key={index}>
              <div
                className={`step p-3 mb-3 rounded ${
                  isActive ? 'active' : ''
                } ${isCompleted ? 'completed' : ''}`}
                onClick={() => handleStepClick(index)}
                style={{ cursor: 'pointer' }}
              >
                <img src={image} alt={`Step ${index}`} />
                <h5>{description}</h5>
              </div>
              {index < steps.length - 1 && (
                <div className={`custom-arrow ${isCompleted ? 'completed-arrow' : ''}`} />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );

};

export default Roadmap;
