import axios from 'axios';
import React, {useState } from 'react';

const SubscriptionForm = () => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [subscribed, setSubscribed] = useState(false);


    const validateEmail = (value) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(value);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setEmailError('');
    };

    const handleSubscribe = () => {
        if (!email || !validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }        
        addEmail();
    };

    const addEmail=()=>{
      const  data={
            email : email,
            sub_data : new Date().toISOString()
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}/Subscription`,data)
        .then((respone)=>{
          setEmail("");
            setSubscribed(true);
        })
        .catch((error)=>{
            //console.log(error);
        });
    }

    return (
        <div className="content">
            <h5>Subscribe to learn more about E-neurotechlabs in your zone:</h5>
            <div className="subscribe-form">
                <input
                    type="email"
                    placeholder="Enter your email"
                    className={`email-input me-2 ${emailError && 'is-invalid'}`}
                    value={email}
                    onChange={handleEmailChange}
                />
                {emailError && <div className="invalid-feedback">{emailError}</div>}
                {subscribed ? (
                    <p className="text-success">You have successfully subscribed!</p>
                ) : (
                    <button
                        type="button"
                        className="essonova-btn"
                        onClick={handleSubscribe}
                    >
                        Subscribe
                    </button>
                )}
            </div>
        </div>
    );
};

export default SubscriptionForm;
