import React from 'react'
import Image_basic from '../../../assests/images/programs/Basics.webp'
import ParrentComponent from '../CommanComponent/ParrentComponent'
import Syllabus from '../../../components/Syllabus/Syllabus'
import Roadmap from '../../../components/Roadmap/Roadmap'
import Faq from '../../../components/Faq/Faq'
import { basic_bootcamp_data } from '../../../context/context'


const Basic = () => {

  window.scrollTo(0, 0);
  return (
    <div className='basic-container'>
      <img className='Image_basic' src={Image_basic} alt='loading...' style={{ height: '20em' }} />
      <div className="text-center">
        <h1 className="gradient__text">Learn the Basics of Web Development Fundamentals</h1>
        <p>HTML, CSS, and Bootstrap, for beginners. Build your foundation with these three pillars of the Internet and ready yourself for the next training Program.</p>
      </div>
      <ParrentComponent
        roadmapcard={basic_bootcamp_data.roadmapcard}
        technologyDetails={basic_bootcamp_data.technologyDetails}
        prerequisites={basic_bootcamp_data.prerequisites}
      />
      <Roadmap />
      <Faq data={basic_bootcamp_data.faq_basic} />
      <Syllabus />
    </div>
  );
}

export default Basic;
