
import React from 'react';
import { partnerData } from '../../context/context';

const PartnerSection = () => {
    return (
        <section id="partner-section">
            <div className="container-partner">
                <div className="section-title">
                    <div className="section-tag fw-bold">Partnering for Success</div>
                    <h3 className='gradient__text'>Unlocking Skills and Growth Together</h3>
                </div>
                <div className='subtitle'>
                    Join us on a journey of collaboration and growth. Our partnership program, powered by E-NeuraTechLab, is designed to help you acquire essential skills and achieve mutual success in real-world projects.
                </div>

                <div className="row">
                    {partnerData.map((partner, index) => (
                        <div
                            key={index}
                            className="col-lg-3 col-md-4 col-sm-6 partner-item"
                        >
                            <div className="partner-logo">
                                <img
                                    src={partner.logoSrc}
                                    alt={partner.name}
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PartnerSection;
