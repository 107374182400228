import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faCoffee, faMoon, faUsers, faBook, faDesktop, faComments } from '@fortawesome/free-solid-svg-icons';

function ScheduleItem({ title, icon, items }) {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="schedule-item-vertical">
                <h5>{title} <FontAwesomeIcon icon={icon} className="colored-icon section-tag" /></h5>
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

function NeedsItem({ title, icon, description }) {
    return (
        <div className="col-lg-3 col-md-6">
            <div className="needs-item-vertical">
                <h5>{title} <FontAwesomeIcon icon={icon} className="colored-icon section-tag" /></h5>
                <p>{description}</p>
            </div>
        </div>
    );
}

function ProgramSchedule() {
    const morningItems = [
        "8:50am - 9am MST: Login to Zoom session for morning Algorithms",
        "10am MST: Recap Algos & Discussion Lecture",
        "11am MST: Group Activities & Setting the Day’s Expectations",
    ];

    const midMorningItems = [
        "12pm MST: Enjoy lunch!",
        "1pm - 5pm MST: Labs including demos, code reviews, and extra sessions",
    ];

    const eveningItems = [
        "Additional Assignments & Self-Study",
        "5pm - 9pm MST: Complete daily assignments, read lessons for the following day",
    ];

    return (
        <section id="day">
            <div className="container-schedule">
                <div className="section-title">
                    <div className="section-tag fw-bold">Program Schedule & Benefits</div>
                    <h3 className='gradient__text'>Unlock Skills of Our Technology Training</h3>
                </div>
                <div className='subtitle mb-3'>
                 Your gateway to cutting-edge tech training. Explore our Program schedule and discover the benefits of joining our program.
                </div>

                <div className="row text-center">
                    {/* Morning */}
                    <ScheduleItem  title="Morning" icon={faSun} items={morningItems} />

                    {/* Mid-Morning */}
                    <ScheduleItem title="Mid-Morning" icon={faCoffee} items={midMorningItems} />

                    {/* Evening */}
                    <ScheduleItem title="Evening" icon={faMoon} items={eveningItems} />
                </div>

                {/* Needs List */}
                <div className="row mt-4">
                    <NeedsItem title="24/7 Cohort Access" icon={faUsers} description="Your access to our LEARN Platform & Discord is available 24/7. Access your materials at whatever time you need them." />
                    <NeedsItem title="Self Study" icon={faBook} description="Most students dedicate 70 - 90 hours a week to self-study, though you may need more or less depending on your learning style and experience." />
                    <NeedsItem title="Lectures" icon={faDesktop} description="Live participation is held Monday - Friday from 9am - 5pm MST. Students should plan for 12+ hour days with 8 hours of instruction." />
                    <NeedsItem title="Optional Hours" icon={faComments} description="Need more assistance understanding a concept? Optional office hours are held Monday - Friday when class is in session an hour prior to the morning kickoff between 8-9am MST." />
                </div>
            </div>
        </section>
    );
}

export default ProgramSchedule;
