import React, { useContext } from 'react'
import Login from './Login/Login';
import ForgetPassword from './Login/ForgetPassword';
import Navbar from '../../components/Navbar/Navbar';
import Registration from './Registration/Registration';
import { StateContext } from '../../components/StateManager/StateManager';
import UserProfile from '../../components/ProfileMenu/UserProfile';
import EditUserProfile from '../../components/ProfileMenu/EditUserProfile';
import PasswordChange from '../../components/ProfileMenu/PasswordChange';
import ProfileMenu from '../../components/ProfileMenu/ProfileMenu';
import AlertBox from '../../components/AlertBox/AlertBox';

const Header = () => {
  const statemanager = useContext(StateContext);
  return (
    <div className="neuratechlab__header">
      <div className="navbar"> <Navbar /></div>

      <div className="form-container">
        {statemanager.loginform.state && <Login onClose={statemanager.loginform.setState} />}
        {statemanager.registerform.state && <Registration onClose={statemanager.registerform.setState} />}
        {statemanager.forgetpassword.state && <ForgetPassword onClose={statemanager.forgetpassword.setState} />}
        {statemanager.displayUserProfile.state && <UserProfile onClose={statemanager.displayUserProfile.setState}/> }
        {statemanager.editUserProfile.state && <EditUserProfile onClose={statemanager.editUserProfile.setState}/> }
        {statemanager.alert_box.alert_box_show.state && <AlertBox/>}
        {statemanager.changePassword.state && <PasswordChange/>}

        {statemanager.showProfileMenu.state && <ProfileMenu/>}

        
      </div>
    </div>


  )
}

export default Header
