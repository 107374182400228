import React from 'react';
import logo from '../../assests/images/logo.png';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import SubscriptionForm from './SubscriptionForm';
import { Link } from 'react-router-dom';


const Footer = () => {
        return (
        <div className="neuratechlab__footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="section-title">
                            <div className="section-tag fw-bold">Reach Us</div>
                            <h3 className='gradient__text'>Ready to Unlock the Full Potential of technology before others</h3>
                        </div>
                        <div className='subtitle mb-3'>
                            Stay connected with E-neurotechlabs: Your gateway to cutting-edge tech training & Explore our resources and get in touch for further information.
                        </div>
                    </div>
                    <SubscriptionForm />
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="neuratechlab__footer-links">
                            <div className="neuratechlab__footer-links_logo">
                               
                                    <img src={logo} alt="essonova neura tech lab" style={{cursor:'pointer'}} onClick={()=>{window.scrollTo(0,0)}}/>
                                <p>
                                    We offer High-End Project Based Enterprising Software Development
                                    Training /Bootcamps in emerging IT technologies - allowing our consultants and
                                    experienced software engineers to explore new technologies.
                                </p>
                            </div>
                            <div className="neuratechlab__social-icons">
                                {/* Use external links for social icons */}
                                <a href="https://www.facebook.com/your-facebook-page" className="social-icon" target="_blank" rel="noopener noreferrer">
                                    <FaFacebook />
                                </a>
                                <a href="https://twitter.com/your-twitter-account" className="social-icon" target="_blank" rel="noopener noreferrer">
                                    <FaTwitter />
                                </a>
                                <a href="https://www.instagram.com/your-instagram-account" className="social-icon" target="_blank" rel="noopener noreferrer">
                                    <FaInstagram />
                                </a>
                                <a href="https://www.linkedin.com/your-linkedin-profile" className="social-icon" target="_blank" rel="noopener noreferrer">
                                    <FaLinkedin />
                                </a>
                                <a href="https://www.youtube.com/your-youtube-channel" className="social-icon" target="_blank" rel="noopener noreferrer">
                                    <FaYoutube />
                                </a>
                                {/* Add more social icons as needed */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="neuratechlab__footer-links">
                            <h4>Links</h4>
                            <p><Link to="/about">About Us</Link></p>
                            <p><Link to="/blog">Blog</Link></p>
                            <p><Link to="/programs/fullstack-development-bootcamp">Full-Stack Development Bootcamp</Link></p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="neuratechlab__footer-links">
                            <h4>Company</h4>
                            <p><Link to="/termsandconditions">Terms & Conditions</Link></p>
                            <p><Link to="/privacypolicy">Privacy Policy</Link></p>
                            <p><Link to="/contact">Contact Us</Link></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="neuratechlab__footer-links">
                            <h4>Get in touch</h4>
                            <p><strong>USA:</strong> 2600 K Avenue, Suite #226 Plano, TX 75074</p>
                          {/*  <p><strong>India:</strong> C-6, Rakshapuram, Mawana Rd, Meerut, Uttar-Pradesh - 250001</p>*/}
                            <p><strong>Mail Us:</strong> <a href="mailto:contactus@essonova.com">contactus@eneurotehlabs.com</a></p>
                                <p><strong>Call Us:</strong> <a href="tel:+1 214 287-4048">+1 (214) 287-4048</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="neuratechlab__footer-copyright">
                        <p>&copy; 2023 E-neurotechlabs (A subsidiary of Essonova Corporation). All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
