import React from 'react';
import AlertBox from '../AlertBox/AlertBox';


const AdminRoute = ({ element }) => {

    if (sessionStorage.getItem('loginData')) {

        const storedData = JSON.parse(sessionStorage.getItem('loginData'));

        return storedData.data.position==='admin'? element : <AlertBox message="Admin Only ,  Go to HomePage"/>; 

    }
    else{
        return <AlertBox message="Admin Only ,  Go to HomePage"/>
    }
};


export default AdminRoute;
