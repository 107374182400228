import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './Layout';
import './App.css';
import { StateContext } from './components/StateManager/StateManager';
import AdminPanel from './components/Admin Panel/AdminPanel';
import Header from './container/Header/Header';
import Frontend from './container/ProgramsComponents/FrontEnd/Frontend';
import Backend from './container/ProgramsComponents/Backend/Backend';
import TermsAndConditions from './container/TermsandConditions/TermsAndConditions';
import Footer from './container/Footer/Footer';
import ContactUs from './container/ContactUs/ContactUs';
import AboutUs from './container/AboutUs/AboutUs';
import Blogs from './container/Blogs/Blogs';
import Fullstack from './container/ProgramsComponents/FullStack/Fullstack';
import Basic from './container/ProgramsComponents/Basic/Basic';
import PrivacyPolicy from './container/TermsandConditions/PrivicyPolicy';
import axios from 'axios';
import BlogRead from './container/Blogs/BlogRead';
import AdminRoute from './components/AdminRoute/AdminRoute';
import { RingLoader } from 'react-spinners';



const App = () => {
  const location = useLocation();
  let [loading, setLoading] = useState(true);
  const { pathname } = location;
  const routesToHideFooter = ['/admin'];

  const shouldHideFooter = routesToHideFooter.includes(pathname);

  const statemanager = useContext(StateContext);


  useEffect(() => {
    (sessionStorage.getItem('loginData')) ? statemanager.loginstate.setState(true) : statemanager.loginstate.setState(false);


    if (sessionStorage.getItem('userlogincredentials')) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/User/login`, JSON.parse(sessionStorage.getItem('userlogincredentials')))
        .then((response) => {
          statemanager.loginstate.setState(true);
          statemanager.logged_in_user_data.setState(response.data);
          sessionStorage.setItem('loginData', JSON.stringify(response.data));
        })
        .catch((error) => {
          //console.log(error);
        });
    }

    axios.get(`${process.env.REACT_APP_API_URL}/Blog`)
      .then((result) => { statemanager.data_from_db.blogdata.setState(result.data); })
      .catch((error) => {
        //console.log(error);
      });

    axios.get(`${process.env.REACT_APP_API_URL}/Program`)
      .then((result) => {
        statemanager.data_from_db.coursedata.setState(result.data);
      })
      .catch((error) => {
        //console.log(error); 
      });
  }
    //added the line below to ignore the missing dependency warning.
    // eslint-disable-next-line
    , []);
  // cant use dependeny in array because it will cause infinite loop

  setTimeout(() => { setLoading(false) }, 1500);

  return (
      <div className={loading?"loading-app-container essonova-loader":"app-container"}>

        {
          loading ? (
            <RingLoader
              color="#21AEA7"
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              <Header />
              <Routes>
                <Route path="/" element={<Layout />} />

                {/* Routes for Program Courses */}
                <Route path="/programs/web-development-fundamentals-bootcamp" element={<Basic />} />
                <Route path="/programs/fullstack-development-bootcamp" element={<Fullstack />} />
                <Route path="/programs/frontend-development-bootcamp" element={<Frontend />} />
                <Route path="/programs/backend-development-bootcamp" element={<Backend />} />


                <Route path="/termsandconditions" element={<TermsAndConditions />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/blog" element={<Blogs />} />
                <Route path='/admin' element={<AdminRoute element={<AdminPanel />} />} />


                {/* // need to make a common comp for all the courses
                      {statemanager.data_from_db.coursedata.state.map((item, index) => {
                        let component = item.program_name.toUpperCase().replace(/ /g, ''); 
                        return (
                          <Route
                            key={index}
                            path={`/program/${item.program_name.toLowerCase().replace(/ /g, '-')}`}
                            element={<component/>}
                          />
                        );
                      })} */}



                {statemanager.data_from_db.blogdata.state.map((item, index) => (
                  <Route
                    key={index}
                    path={`/blog/${item.blog_title.toLowerCase().replace(/ /g, '-')}`}
                    element={<BlogRead data={item} />}
                  />
                ))}


              </Routes>


              {!shouldHideFooter && <Footer />}
              </>
          )}

      </div>

  )
}

export default App;
