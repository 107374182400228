import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);
  useEffect(() => {
    document.querySelector('.privacy-container').style.opacity = 1;
  }, []);

  return (
    <Container className="privacy-container">
      <h1 className=" gradient__text text-center my-4 p-2">Privacy Policy</h1>
      <p>
        Welcome to E-neuratechlab ("we", "our", or "us"). This Privacy Policy outlines how we collect, use, and protect your personal information when you use our training website (the "Service").
      </p>
      
      <h2 className="mt-5">1. Information We Collect</h2>
      <p>We may collect personal information, such as your name, email address, contact details, and payment information when you register for an account, make a purchase, or interact with our Service. We may also collect non-personal information such as your IP address and browser type.</p>
      
      <h2 className="mt-5">2. How We Use Your Information</h2>
      <p>We may use your information to provide and improve our Service, process payments, communicate with you, personalize your experience, and send you updates and promotional materials. We may also use cookies and similar tracking technologies to enhance your browsing experience.</p>
      
      <h2 className="mt-5">3. Data Sharing and Disclosure</h2>
      <p>We may share your personal information with trusted third parties for purposes such as payment processing, analytics, and marketing. We do not sell your personal information to third parties. We may disclose your information to comply with legal obligations or protect our rights.</p>
      
      <h2 className="mt-5">4. Security Measures</h2>
      <p>We implement industry-standard security measures to protect your personal information. However, please be aware that no method of data transmission over the internet is completely secure, and we cannot guarantee the absolute security of your data.</p>
      
      <h2 className="mt-5">5. Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal information. You can also opt-out of receiving marketing communications from us. To exercise these rights, please contact us at [Your Contact Information].</p>
      
      <h2 className="mt-5">6. Changes to Privacy Policy</h2>
      <p>We may update this Privacy Policy to reflect changes in our practices. We will notify you of any significant changes through our website or other means. Your continued use of the Service after such changes constitutes your acceptance of the updated policy.</p>
      
      <h2 className="mt-5">7. Contact Us</h2>
      <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at Mail Us: contactus@essonova.com,Call Us: 972-241-0650.</p>
    </Container>
  );
}

export default PrivacyPolicy;
