import React, { useContext, useMemo, useState } from 'react';
import { StateContext } from '../StateManager/StateManager';
import './admin_css/add_blog.css';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import defualt_img from '../../assests/images/blog/image/default-blog-image.jpg'
import RingLoader from "react-spinners/RingLoader";


const AddBlog = () => {
    const statemanager = useContext(StateContext);
    // const editor = useRef(null);

    const [loading, setLoading] = useState(false);


    const currentblog = statemanager.adminstates.adminbuttons.edit_blog_current_item.state;

    const [newBlog, setNewBlog] = useState(currentblog != null ? currentblog : {
        blog_title: '',
        author_name: '',
        blog_created_date: new Date().toISOString(),
        blog_image: '',
        blog_description: '',
        category: '',
    });

    //const inputRef = useRef(null);

    const handleFileChange = (e) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setNewBlog({ ...newBlog, blog_image: reader.result })
        })
        reader.readAsDataURL(e.target.files[0])
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBlog({ ...newBlog, [name]: value });
    };

    //token for blog write or update authentication
    const loginData = JSON.parse(sessionStorage.getItem('loginData'));
    const token = loginData.token;

    const config = useMemo(() => ({
        headers: {
            Authorization: `Bearer ${token}`
        }
    }), [token]);

    const updateData = () => {
        setLoading(true); //Loader State
        if (newBlog.id) {
            axios
                .put(`${process.env.REACT_APP_API_URL}/Blog`, newBlog,config)
                .then((response) => {
                    setLoading(false);  //Loader State

                    statemanager.alert_box.alert_box_show.setState(true);
                    statemanager.alert_box.alert_box_message.setState('Blog Updated');
                    statemanager.alert_box.alert_box_message_color.setState('green');
                    setTimeout(() => {
                        statemanager.alert_box.alert_box_show.setState(false);
                    }, 2000);
                })
                .catch((error) => {
                    setLoading(false);  //Loader State

                    statemanager.alert_box.alert_box_show.setState(true);
                    statemanager.alert_box.alert_box_message.setState('Failed');
                    statemanager.alert_box.alert_box_message_color.setState('red');
                    setTimeout(() => {
                        statemanager.alert_box.alert_box_show.setState(false);
                    }, 2000);
                    console.error(error);
                });

        }
        else {
            setLoading(true);  //Loader State

            axios
                .post(`${process.env.REACT_APP_API_URL}/Blog`, newBlog,config)
                .then((response) => {
                    setLoading(false);  //Loader State

                    statemanager.alert_box.alert_box_show.setState(true);
                    statemanager.alert_box.alert_box_message.setState('Blog Added');
                    statemanager.alert_box.alert_box_message_color.setState('green');
                    setTimeout(() => {
                        statemanager.alert_box.alert_box_show.setState(false);
                    }, 2000);
                })
                .catch((error) => {
                    setLoading(false);  //Loader State

                    statemanager.alert_box.alert_box_show.setState(true);
                    statemanager.alert_box.alert_box_message.setState('Failed');
                    statemanager.alert_box.alert_box_message_color.setState('red');
                    setTimeout(() => {
                        statemanager.alert_box.alert_box_show.setState(false);
                    }, 2000);
                    console.error(error);
                });
        }
    };

    return (
        <div className="admin-add-blog-container">
            <div className="admin-add-blog-container__left-panel">
                {(newBlog.blog_image) ? (
                    <div className="admin-add-blog-container__image">
                        <img src={newBlog.blog_image} alt={newBlog.blog_title.toLowerCase()}/>
                    </div>
                ) : (
                    <div className="admin-add-blog-container__image">
                        <img src={defualt_img} alt="blog" />
                    </div>
                )}
                <div className="blog-edit-image">
                    <input
                        type="file"
                        name="blog_image"
                        onChange={handleFileChange}
                    />
                </div>
                <div className="admin-add-blog-container__blog-title">
                    <input
                        type="text"
                        name="blog_title"
                        value={newBlog.blog_title}
                        onChange={handleInputChange}
                        placeholder='Title'
                    />
                </div>

                <div className="admin-add-blog-container__blog-author">
                    <input
                        type="text"
                        name="author_name"
                        placeholder='Author'
                        value={newBlog.author_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="admin-add-blog-container__blog-date">
                    <input
                        type="text"
                        name="blog_created_date"
                        value={new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
                    />
                </div>
                <div className="admin-add-blog-container__blog-category">
                    <select>
                        <option>choose category</option>
                        <option>Aritficial Intelligence</option>
                        <option>Latest in Tech</option>
                        <option>Cyber Security</option>
                        <option>Web Development</option>
                        <option>Software Development</option>
                        <option>Skill Development</option>

                    </select>
                </div>
                <div>
                    <ReactQuill
                        className='admin-add-blog-container__blog-content'
                        value={newBlog.blog_description}
                        onChange={(value) => setNewBlog({ ...newBlog, blog_description: value })}
                        placeholder='Say something...'
                    />

                    {/* <JoditEditor
                        ref={editor}
                        className='admin-add-blog-container__blog-content'
                        value={newBlog.blog_description}
                        onChange={(value) => setNewBlog({ ...newBlog, blog_description: value })}
                        placeholder='Say something...'
                    /> */}

                </div>


            </div>
            {loading ? (
                <RingLoader
                    color="#21AEA7"
                    loading={loading}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            ) : (
                <div className="admin-add-blog-container__right-panel">
                    <div className="admin-add-blog-container__submit-button"
                        onClick={updateData}>
                        Submit</div>

                    <div className="admin-add-blog-container__cancel-button"
                        onClick={() => statemanager.adminstates.adminbuttons.add_edit_blog.setState(false)}>
                        Cancel
                    </div>
                </div>
            )}

        </div >
    );
}

export default AddBlog
