import React, { useContext} from 'react'
import '../../assests/css/style.css'
import { StateContext } from '../StateManager/StateManager';


const AlertBox = ({message}) => {
  const statemanager = useContext(StateContext);
  return (
    <div className={
      (statemanager.registerform.state || statemanager.loginform.state)?
      "non-admin-panel-alert-box-container":"admin-panel-alert-box-container"
    }>
        <div className="alert-box-container__close--button">
        <button className=' section-tag' onClick={()=>{
        statemanager.alert_box.alert_box_show.setState(false)}}
        > 
        X </button>
        </div>
        <div className='alert-box-container__message' style={{color:statemanager.alert_box.alert_box_message_color}}>
        <h3>{message ? message : statemanager.alert_box.alert_box_message.state}</h3>
        </div>
        
    </div>
  )
}

export default AlertBox;
