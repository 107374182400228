import React, { useContext } from 'react'
import { StateContext } from '../../components/StateManager/StateManager'
import { Link } from 'react-router-dom';
import { course_card_data } from '../../context/context';
import parse from 'html-react-parser';


const BlogRead = ({ data }) => {
    window.scroll(0,0);// scroll to top
    const statemanager = useContext(StateContext);
    const blogdata = statemanager.data_from_db.blogdata.state;

    const filtersuggestedblog = blogdata
        .filter((item) => item.category === data.category)



    return (
        <div className='blog-read-section'>
         <div className='blog-read-container'>
            <div className="blog-item">
                <div className='blog-image'>
                    <img src={data.blog_image} alt={data.blog_title} />
                </div>
                <div className="blog-item-text">
                    <h1>{data.blog_title}</h1>
                    <p><b>Author : </b>{data.author_name}</p>
                    <p><b>Published on : </b>{data.blog_created_date}</p><br></br>
                    {parse(data.blog_description)}
                </div>
            </div>
            <div className="bootcamp-card ">
                {/* Suggested Blogs Section */}
                <div>
                    <h2 className="fw-bold text-center mb-2">BootCamps</h2>
                    <div className="row ">
                        {course_card_data.map((item) => (
                            <div className="col-10 m-auto mb-4" key={item.id}>
                                <div className="card" style={{height:450}}>
                                    <img src={item.img} className="card-img-top" alt={item.title} />
                                    <div className="card-body">
                                        <h2 className="card-title">{item.title}</h2>
                                        <div className="card-text">{item.description}</div>
                                        <Link to={item.path}><button className="essonova-btn">
                                            Read
                                        </button> </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
           

        </div>

        <div className="blog-suggested">
    <div>
        <h2 className="fw-bold text-center">Suggested Blogs</h2>
        <div className="row">
            {filtersuggestedblog.map((item) => (
                <div className="col-lg-6 col-md-6 col-12 mb-4" key={item.id}>
                    <div className="card">
                        <img src={item.blog_image} className="card-img-top" alt={item.blog_title} />
                        <div className="card-body">
                            <h2 className="card-title">{item.blog_title}</h2>
                            <p className="card-text">Author: {item.author_name}, Date: {item.blog_created_date}</p>
                            <p className="card-text">{parse(item.blog_description)}</p>
                            <Link to={`/blog/${item.blog_title.toLowerCase().replace(/ /g, '-')}`}>
                                <button className="essonova-btn">
                                    Read
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
</div>


        </div>
       

        
    )
}

export default BlogRead
