import React, {useEffect, useState } from 'react';
import dimg from '../../assests/images/default-profile-img.png'

const UserProfile = ({ onClose }) => {
    const [userData, setUserData] = useState({
        id: "",
        fname: "",
        lname: "",
        email: "",
        phone: "",
        country: "",
        state: "",
        username: "",
        image: ""
    });

    useEffect(() => {
        if (sessionStorage.getItem('loginData')) {
            const storedData = JSON.parse(sessionStorage.getItem('loginData'));
            setUserData({
                id: storedData.data.id,
                fname: storedData.data.fname,
                lname: storedData.data.lname,
                email: storedData.data.email,
                phone: storedData.data.phone,
                image: storedData.data.image,
                username: storedData.data.username,
                state: storedData.data.state,
                country: storedData.data.country,
            });
        }
    }, []);

    return (
        <section className="user-profile-essonova">
            <div className="container-user-profile">
                <div className="user-profile-button-div">
                    <button className="section-tag" onClick={() => onClose(false)}>X</button>
                </div>
                <div className="section-title text-center wow fadeInDown">
                    <div className="section-tag fw-bold"></div>
                    <h3 className='gradient__text'>Welcome {userData.fname + ' ' + userData.lname}</h3>
                </div>
                <div className="user-profile-form wow fadeInUp">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            {userData.image.length>1000 ? (
                                <div className="image-container m-auto">
                                    <img src={userData.image} alt="User" className="rounded-circle img-fluid" />
                                </div>
                            ) : (
                                <div className="image-container m-auto">
                                    <img src={dimg} alt="User" className="rounded-circle img-fluid" />
                                </div>
                            )}
                        </div>

                        <div className="col-lg-6 col-md-6 col-12">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="First Name">First Name</label>
                                    <input type="text" className="form-control" placeholder="First Name" name="fname" value={userData.fname} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Last Name">Last Name</label>
                                    <input type="text" className="form-control" placeholder="Last Name" name="lname" value={userData.lname} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Username">Username</label>
                                    <input type="Username" className="form-control" placeholder="Username" name="username" value={userData.username} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Email">Email</label>
                                    <input type="email" className="form-control" placeholder="Email" name="email" value={userData.email} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Phone">Phone</label>
                                    <input type="text" className="form-control" placeholder="Mobile" name="phone" value={userData.phone} required />
                                </div>
                            </form>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

export default UserProfile;
