import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';

const TermsAndConditions = () => {
  window.scrollTo(0, 0);
  useEffect(() => {
    document.querySelector('.terms-container').style.opacity = 1;
  }, []);

  return (
    <Container className="terms-container">
      <h1 className=" gradient__text text-center my-4">Terms and Conditions</h1>
      <p>
        Welcome to E-neuratechlab ("we", "our", or "us"). These Terms and Conditions govern your use of our training website (the "Service"). By accessing or using the Service, you agree to be bound by these Terms and Conditions.
      </p>

      <h2 className="mt-5">1. Acceptance of Terms</h2>
      <p>By using our Service, you agree to comply with these Terms and Conditions. If you do not agree with any part of these terms, please do not use our Service.</p>

      <h2 className="mt-5">2. Registration and Accounts</h2>
      <p>You may be required to create an account to access certain features of our Service. You are responsible for maintaining the confidentiality of your account credentials and are liable for all activities that occur under your account.</p>

      <h2 className="mt-5">3. Privacy Policy</h2>
      <p>Your use of our Service is also governed by our Privacy Policy. Please review our Privacy Policy to understand our data collection and usage practices.</p>

      <h2 className="mt-5">4. Use of Content</h2>
      <p>All content provided on our Service is for informational and educational purposes only. You may not copy, distribute, modify, or reproduce any content without our prior written consent.</p>

      <h2 className="mt-5">5. Termination</h2>
      <p>We reserve the right to terminate or suspend your account and access to the Service at our discretion, without notice, for any violation of these Terms and Conditions.</p>

      <h2 className="mt-5">6. Changes to Terms</h2>
      <p>We may revise these Terms and Conditions at any time without notice. By continuing to use the Service after changes are made, you agree to be bound by the revised terms.</p>

      <h2 className="mt-5">7. Governing Law</h2>
      <p>These Terms and Conditions are governed by the laws of India. Any disputes arising from or relating to these terms will be subject to the exclusive jurisdiction of the courts in [Your City/Region].</p>

      <h2 className="mt-5">Contact Us</h2>
      <p>If you have any questions or concerns regarding these Terms and Conditions, please contact us at Mail Us: contactus@essonova.com, Call Us: 972-241-0650.</p>
    </Container>
  );
}

export default TermsAndConditions;
