import React from 'react'
import Image_Backend from '../../../assests/images/programs/frontend.png'
import ParrentComponent from '../CommanComponent/ParrentComponent'
import Syllabus from '../../../components/Syllabus/Syllabus'
import Roadmap from '../../../components/Roadmap/Roadmap'
import Faq from '../../../components/Faq/Faq'
import { backend_data } from '../../../context/context'



const Backend = () => {
  window.scrollTo(0, 0);
  
  return (
    <div>
      <img className='Images_Backend' src={Image_Backend} alt='lodding' style={{ height: '20em' }} />
      <div className="text-center">
        <h1 className="gradient__text">Learn the Back-end Development </h1>
        <p>HTML, CSS, and Bootstrap, for beginners. Build your foundation with these three pillars of the Internet and ready yourself for the next training Program.</p>
      </div>
      <ParrentComponent
        roadmapcard={backend_data.roadmapcard}
        technologyDetails={backend_data.technologyDetails}
        prerequisites={backend_data.prerequisites}
      />
      <Roadmap />
      <Faq data = {backend_data.faq_backend} />
      <Syllabus />
    </div>
  )
}


export default Backend; 
