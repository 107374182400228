import React, { useContext, useState } from 'react';
import './admin_css/blogsmanage.css'
import { StateContext } from '../StateManager/StateManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlog } from '@fortawesome/free-solid-svg-icons';


const BlogsManage = () => {
  const statemanager = useContext(StateContext);
  const [searchInput, setSearchInput] = useState('');

  const [currentpageno, setCurrentPageNo] = useState(1);

  const dataperpage = 10;

  let start_index = currentpageno === 1 ? 0 : (currentpageno * dataperpage - dataperpage);
  let end_index = currentpageno === 1 ? 10 : (currentpageno * dataperpage);

  const userdata = statemanager.data_from_db.blogdata.state;
  const totalpages = Math.ceil((userdata.length) / dataperpage);
  let currentdata = userdata.slice(start_index, end_index);

  const filteredBlog = currentdata.filter((item) => {
    return (
      (searchInput === '' || ((item.blogTitle).toLowerCase().includes(searchInput.toLowerCase())))
    );
  });


  return (
    <div className='admin-blogs-manage-container'>
      <div className="admin-blogs-manage-container__table">
        <table >
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Author</th>
              <th>Created On</th>
              <th>Category</th>
              <th>Image</th>
              <th>Content</th>

              <th style={{ cursor: 'pointer' }}
                onClick={() => {
                  statemanager.adminstates.adminbuttons.edit_blog_current_item.setState(null);
                  statemanager.adminstates.adminbuttons.add_edit_blog.setState(true);
                  statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false);
                }}>+<FontAwesomeIcon icon={faBlog} /></th>
              <th>
                <input
                  type='text'
                  name='search'
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  placeholder='search blog'
                  className='admin-blog-search' />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredBlog.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.blog_title}</td>
                <td>{item.author_name}</td>
                <td>{item.blog_created_date.substring(0, 10)}</td>
                <td>{item.category}</td>
                <td><img
                  src={item.blog_image}
                  alt="Selected"
                  className="selected-image"
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
          />
                </td>
                <td>{item.blog_description.substring(0, 20) + "..."}</td>
                <td>
                  <div className='edit-button'>
                    <button className="btn btn-primary"
                      onClick={() => {
                        statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false);
                        statemanager.adminstates.adminbuttons.edit_blog_current_item.setState(item);
                        statemanager.adminstates.adminbuttons.add_edit_blog.setState(true);

                      }}>
                      Edit</button>
                  </div>
                </td>
                <td>
                  <div className='delete-button'>
                    <button className="btn btn-danger"
                      onClick={() => {
                        statemanager.adminstates.adminbuttons.add_edit_blog.setState(false);
                        statemanager.adminstates.adminbuttons.delete_button.delete_component_current_data.setState(item);
                        statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(true);
                      }}>
                      Delete</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="admin-usermanage-container__page-numbers">
        <div className="prev-page-button" onClick={() => {
          currentpageno === 1 ? setCurrentPageNo(1) : setCurrentPageNo(currentpageno - 1)
        }}>Prev</div>
        <div className="current-page-number">{currentpageno}&nbsp;/&nbsp;{totalpages}</div>

        <div className="next-page-button" onClick={() => {
          currentpageno === totalpages ? setCurrentPageNo(totalpages) : setCurrentPageNo(currentpageno + 1)
        }}>Next</div>
      </div>


    </div>
  )
}

export default BlogsManage
