import React, { useState } from 'react';
import { testimonialsData } from '../../context/context';


const TestimonialSlider = () => {
    const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

    const nextTestimonial = () => {
        setCurrentTestimonialIndex((prevIndex) =>
            prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevTestimonial = () => {
        setCurrentTestimonialIndex((prevIndex) =>
            prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
        );
    };

    const testimonial = testimonialsData[currentTestimonialIndex];

    return (
        <div className="testimonial-slider container mt-5">
            <div className="section-tag fw-bold text-center">Testimonials</div>
            <div className="title-holder">
                <h3 className="gradient__text">What Our Students Say</h3>
            </div>
            <div className="subtitle mb-3">
                Discover the experiences of our students who have benefited from our comprehensive software development training program. Hear what they have to say about their journey with E-NeuraTechLab and how it prepared them for real-world projects.
            </div>

            <div className="row">
                <div className="col-md-2 col-3">

                    <button onClick={prevTestimonial} className="essonova-btn arrow-button">
                        &#9664;
                    </button>
                </div>
                <div className="col-md-8 col-6">
                    <div className="testimonial">

                        <img src={testimonial.image} alt={testimonial.name} className="rounded-image" />
                        <p className="testimonial-text">{testimonial.text}</p>
                        <p className="author">- {testimonial.name}</p>
                        <p className="fw-bold section-tag">{testimonial.designation}</p>
                    </div>
                </div>
                <div className="col-md-2 col-3">

                    <button onClick={nextTestimonial} className="essonova-btn arrow-button">
                        &#9654;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TestimonialSlider;