import React from 'react';
import { instructors } from '../../context/context';


const InstructorSection = () => {
  return (
    <section className="instructor-section">
      <div className="container">
        <div className="section-title">
          <div className="section-tag fw-bold">Meet Our Instructors</div>
          <h3 className='gradient__text'>Experienced Experts Ready to Guide You</h3>
        </div>
        <div className="instructor-section-card-container">
        {instructors.map((instructor) => (
            <div  key={instructor.id}  className="instructor-section-card">
                <div className="instructor-section-card__image">
                    <img src={instructor.image} alt="Course-details" className="instructor-img-top" />
                </div>
                <div className="instructor-section-card__text">
                    <h4> {instructor.name}</h4>
                    <h5>{instructor.designation}</h5>
                    <p> {instructor.description}</p>
                </div>
            </div>
          ))}

        </div>
          
      </div>
    </section>
  );
};

export default InstructorSection;
