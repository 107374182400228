import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../assests/css/style.css'; // Fix the typo in 'assets'
import introVideo from '../../assests/walter_Message.mp4'; // Fix the typo in 'assets'
import { FaClock, FaUsers, FaChalkboardTeacher, FaHandshake, FaComment } from 'react-icons/fa'; // Import icons from React Icons


const Introduction = () => {
    return (
        <Container>
            <Row>
                {/* Column for Video (on smaller screens, it takes full width) */}
                <Col md={6} className="video-column" order-md={1}>
                    <video
                        title="Introduction Video"
                        className="video-iframe"
                        controls // Added controls for video playback
                    >
                        <source src={introVideo} type="video/mp4" />
                    </video>
                </Col>
                {/* Column for Text Content */}
                <Col md={6} className="text-column" order-md={2}>
                    <div className="text-content">
                        <h3 className='gradient__text text-center'>Welcome to NeuratechLab Bootcamps</h3>
                        <h5 className='section-tag fw-bold text-center'>Unlock Your Coding Potential</h5>
                        <ul className="program-list">
                            <li>
                                <FaClock className="icon" /> On weekdays, complete 10-16 hours of coding challenges and projects at your own pace.
                            </li>
                            <li>
                                <FaUsers className="icon" /> Collaborate daily with your 20 fellow coders and experienced instructors.
                            </li>
                            <li>
                                <FaChalkboardTeacher className="icon" /> Attend 4-hour live coding workshops on weekends led by industry experts for hands-on guidance.
                            </li>
                            <li>
                                <FaHandshake className ="icon" /> Stay connected with your peers in our dedicated coding community and work together on real-world projects.
                            </li>
                            <li>
                                <FaComment className="icon" /> Receive continuous support and mentoring from our instructors via a private chat throughout the week.
                            </li>
                        </ul>
                        <a href="/codemaster" className="essonova-btn">
                            Get Started
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Introduction;