import axios from 'axios';
import React, {useEffect, useState } from 'react';
import { RingLoader } from 'react-spinners';

const EditUserProfile = ({ onClose }) => {

    const [requestsuccess, setRequestSuccess] = useState(null);
    const [requestfail, setRequestFail] = useState(null);

    const [loading, setLoading] = useState(false);

    const [userData, setUserData] = useState({
        id: "",
        fname: "",
        lname: "",
        email: "",
        phone: "",
        country: "",
        state: "",
        username: "",
        image: ""
    });


    useEffect(() => {
        if (sessionStorage.getItem('loginData')) {
            const storedData = JSON.parse(sessionStorage.getItem('loginData'));
            setUserData({
                id: storedData.data.id,
                fname: storedData.data.fname,
                lname: storedData.data.lname,
                email: storedData.data.email,
                phone: storedData.data.phone,
                image: storedData.data.image,
                username: storedData.data.username,
                state: storedData.data.state,
                country: storedData.data.country,
            });
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleFileChange = (e) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setUserData({ ...userData, image: reader.result })
        })
        reader.readAsDataURL(e.target.files[0])
    };

    const saveData = () => {
        setLoading(true);  //Loader State
        axios
            .put(`${process.env.REACT_APP_API_URL}/User`, userData)
            .then((response) => {
                setLoading(false);  //Loader State
                setRequestFail(null);
                setRequestSuccess("Profile Updated Successfully");
            })
            .catch((error) => {
                setLoading(false);  //Loader State
                setRequestFail("Failed to Update");
                setRequestSuccess(null);
                console.error(error);
            });


    };

    return (
        <section className="user-profile-essonova">
            <div className="container-user-profile">
                <div className="user-profile-button-div">
                    <button className="section-tag" onClick={() => onClose(false)}>X</button>
                </div>
                <div className="section-title text-center wow fadeInDown">
                    <div className="section-tag fw-bold"></div>
                    <h3 className='gradient__text'>Edit Info</h3>
                </div>
                <div className="user-profile-form wow fadeInUp">
                    <div className="col-lg-12 col-md-12 col-12">
                    {loading ? (
                            <RingLoader
                                color="#21AEA7"
                                loading={loading}
                                size={150}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        ) : (
                            <form>
                            <div className="form-group">
                                <label htmlFor="First Name">First Name</label>
                                <input type="text" className="form-control" placeholder="First Name" name="fname" value={userData.fname} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Last Name">Last Name</label>
                                <input type="text" className="form-control" placeholder="Last Name" name="lname" value={userData.lname} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Username">Username</label>
                                <input type="Username" className="form-control" placeholder="Username" name="username" value={userData.username} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Email">Email</label>
                                <input type="email" className="form-control" placeholder="Email" name="email" value={userData.email} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Phone">Phone</label>
                                <input type="text" className="form-control" placeholder="Mobile" name="phone" value={userData.phone} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Image">Image</label>
                                <input type="file" className="form-control" name="image" onChange={handleFileChange} />
                            </div>
                            <button type="button" className="essonova-btn" onClick={saveData}>Save</button>
                        </form>
                        )}
                        
                        {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
                        {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}

                    </div>
                </div>
            </div>
        </section>
    );
}

export default EditUserProfile;
