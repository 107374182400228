import React, { useContext, useState } from 'react'
import { StateContext } from '../StateManager/StateManager';
import './admin_css/add_course.css'
import axios from 'axios';
import { RingLoader } from 'react-spinners';



const AddCourse = () => {

    const [requestsuccess, setRequestSuccess] = useState(null);
    const [requestfail, setRequestFail] = useState(null);

    const [loading, setLoading] = useState(false);

    const statemanager = useContext(StateContext);
    let item = statemanager.adminstates.adminbuttons.edit_course_current_item.state;
    const [newCourse, setNewCourse] = useState(item != null ? item : {
        program_name: '',
        program_created_by: '',
        program_price: '',
        program_time: new Date().toISOString(),
        program_image: '',
        program_desc: '',
        program_status: 'Active'
    });




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCourse({ ...newCourse, [name]: value });
    };

    const handleFileChange = (e) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setNewCourse({ ...newCourse, program_image: reader.result })
        })
        reader.readAsDataURL(e.target.files[0])
    };


    const updateData = () => {
        setLoading(true);  //Loader State
        if (item) {
            axios.put(`${process.env.REACT_APP_API_URL}/Program`, newCourse)
                .then(response => {
                    setLoading(false);  //Loader State
                    setRequestFail(null);
                    setRequestSuccess("Program Updated");
                })
                .catch(error => {
                    setLoading(false);  //Loader State
                    setRequestFail("Failed");
                    setRequestSuccess(null);
                    //console.log(error);
                });
        }

        else {

            axios.post(`${process.env.REACT_APP_API_URL}/Program`, newCourse)
                .then(response => {
        setLoading(false);  //Loader State

                    setRequestFail(null);
                    setRequestSuccess("Program Added");
                })
                .catch(error => {
        setLoading(false);  //Loader State

                    //console.log(error);
                    setRequestFail("Failed");
                    setRequestSuccess(null);
                });
        }
    }


    return (
        <div className="admin-add-course-container">
            <table className="admin-add-course-container__table">
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Title</td>
                        <td>
                            <input
                                className='admin-add-course-container__table__td__input'
                                type="text"
                                name="program_name"
                                value={newCourse.program_name}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Created By</td>
                        <td>
                            <input
                                className='admin-add-course-container__table__td__input'
                                type="text"
                                name="program_created_by"
                                value={newCourse.program_created_by}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Price</td>
                        <td>
                            <input
                                className='admin-add-course-container__table__td__input'
                                type="text"
                                name="program_price"
                                value={newCourse.program_price}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Created At</td>
                        <td>
                            <input
                                className='admin-add-course-container__table__td__input'
                                type="text"
                                name="program_time"
                                value={newCourse.program_time.substring(0, 10)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Image</td>
                        <td>
                            <input
                                className='admin-add-course-container__table__td__input'
                                type="file"
                                name="program_image"
                                onChange={handleFileChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>
                            <input
                                className='admin-add-course-container__table__td__input'
                                type="text"
                                name="program_desc"
                                value={newCourse.program_desc}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>
                            <input
                                className='admin-add-course-container__table__td__input'
                                type="text"
                                name="program_status"
                                value={newCourse.program_status}
                                onChange={handleInputChange}
                            />
                        </td>
                    </tr>
                    {loading?(
                         <RingLoader
                         color="#21AEA7"
                         loading={loading}
                         size={150}
                         aria-label="Loading Spinner"
                         data-testid="loader"
                     />
                    ):(
                        <tr>
                        <td >
                            <div className="admin-add-course-container__table__submit-button"
                                onClick={updateData}>Submit</div>
                        </td>
                        <td>
                            <div className="admin-add-course-container__table__cancel-button"
                                onClick={() => {
                                    statemanager.adminstates.adminbuttons.add_edit_course.setState(false);
                                    setRequestFail(null);
                                    setRequestSuccess(null);
                                }}>
                                Cancel
                            </div>
                        </td>
                    </tr>  
                    )}
                </tbody>
            </table >
            {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
            {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}
        </div >
    );
}

export default AddCourse
