import React, { useContext, useState } from 'react';
import { StateContext } from '../../../components/StateManager/StateManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Assuming you're using FontAwesome
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // Icons for password toggle
import axios from 'axios';
import { RingLoader } from 'react-spinners';



const Login = () => {
  const [requestsuccess, setRequestSuccess] = useState(null);
  const [requestfail, setRequestFail] = useState(null);

  let [loading, setLoading] = useState(false);// loader state

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const forgetpassword = useContext(StateContext);
  const loginformshow = useContext(StateContext);
  const [passwordType, setPasswordType] = useState('password');
  const [showPassword, setShowPassword] = useState(false);
  const statemanager = useContext(StateContext);


  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setPasswordType(showPassword ? 'password' : 'text');
  };

  const handleLogin = () => {
    // Perform username and password validation here
    if (username.trim() === '') {
      setUsernameError('Username cannot be empty');
    } else {
      setUsernameError(null);
    }

    if (password.trim() === '') {
      setPasswordError('Password cannot be empty');
    } else {
      setPasswordError(null);
    }
  };

  const uservalidate = () => {
    if(username.length<1 || password.length<1)
    {
      return;
    }
    
    let userlogincredentials = {
      Username: username,
      Password: password
    }
    sessionStorage.setItem('userlogincredentials', JSON.stringify(userlogincredentials));
    // create this sessionStorage for app.js api call ,so wehn app.js is called again user data will be again called from db. 

    setLoading(true);  //Loader State
    axios
        .post(`${process.env.REACT_APP_API_URL}/User/login`, JSON.parse(sessionStorage.getItem('userlogincredentials')))
      .then((response) => {
        statemanager.loginstate.setState(true);
        statemanager.logged_in_user_data.setState(response.data);
        sessionStorage.setItem('loginData', JSON.stringify(response.data));
        setRequestSuccess("Logged In Successfully");

        setRequestFail(null);
        setLoading(false);  //Loader State
        setTimeout(() => {
          setRequestSuccess(null);
          setRequestFail(null);
          statemanager.loginform.setState(false);
        }, 2000);

      })
      .catch((error) => {
        setLoading(false);  //Loader State
        //console.log(error);
        setRequestSuccess(null);
        setRequestFail("Invalid Credentials");
      });
  }

  return (
    <section className="login-essonova">
      <div className="container_login">
        <div className="login-close-div">
          <button className="section-tag" onClick={() => {
            setRequestSuccess(null)
            setRequestFail(null)
            statemanager.loginform.setState(false)
          }}> X </button>
        </div>
        <div className="section-title text-center wow fadeInDown">
          <div className="section-tag fw-bold"></div>
          <h3 className='gradient__text'>Your Account</h3>
        </div>

        <div className="login-form wow fadeInUp essonova-loader">

          {loading ? (
            <RingLoader
              color="#21AEA7"
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (

            <form>
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={username}
                      onChange={handleUsernameChange}
                      required
                    />
                    {usernameError && <div className="error-message" style={{ color: 'red' }}>{usernameError}</div>}
                  </div>
                </div>

                <div className="col-lg-12 col-md-6 col-12">
                  <div className="form-group">
                    <div className="input-group"> {/* Wrap the input and icon in an input-group */}
                      <input
                        type={passwordType}
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                      <div className="input-group-append"> {/* Append the icon */}
                        <span>
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={togglePasswordVisibility}
                            className="password-toggle-icon" // Apply the CSS class here
                          />
                        </span>
                      </div>
                    </div>
                    {passwordError && <div className="error-message" style={{ color: 'red' }}>{passwordError}</div>}
                  </div>
                </div>

                <div className="container_login--checkobox-and-forget-password">
                  <div className="container_login--check-box">
                    <div className="form-check mb-3">
                      <input type="checkbox" className="form-check-input" id="consentCheckbox" />
                      <label htmlFor="consentCheckbox"> &nbsp;&nbsp; Remember Me</label>
                    </div>
                  </div>
                  <div className="container_login--forget-password">
                    <p className="section-tag" onClick={() => {
                      forgetpassword.forgetpassword.setState(true);
                      loginformshow.loginform.setState(false);
                    }}>Forget Password</p>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-12">
                  <button type="button" className="essonova-btn" onClick={() => {
                    handleLogin();
                    uservalidate();
                  }}>
                    Log In!
                  </button>
                </div>
                {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
                {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}
              </div>
            </form>


          )}
        </div>
      </div>
    </section>
  );
};

export default Login;
