import axios from 'axios'
import React, { useContext, useState } from 'react'
import { StateContext } from '../StateManager/StateManager'
import './admin_css/delete_user.css'
import { RingLoader } from 'react-spinners';


const DeleteUser = () => {
    const [requestsuccess, setRequestSuccess] = useState(null);
    const [requestfail, setRequestFail] = useState(null);

    const [loading, setLoading] = useState(false);  //Loader State

    const [requestsubmit, setRequestSubmit] = useState(false); //chking if api request is success then show only close button


    const statemanager = useContext(StateContext);
    const item = statemanager.adminstates.adminbuttons.delete_button.delete_component_current_data.state;

    const deleteUser = () => {
        setLoading(true); //Loader State
        axios.delete(`${process.env.REACT_APP_API_URL}/User/${item.id}`)
            .then(response => {
                setLoading(false); //Loader State
                setRequestFail(null);
                setRequestSuccess("Entity Marked InActive");
                setRequestSubmit(true); // chking if api request is success then show only close button
                setTimeout(() => {
                    statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false)
                    setRequestSuccess(null);
                });
            })
            .catch(error => {
                setLoading(false); //Loader State
                //console.log(error);
                setRequestFail("Failed");
                setRequestSuccess(null);
            });
    }
    return (
        <div className='delet-user-conainer'>
            <div className="delete-user-container__message">
                This will mark the selection as INACTIVE
            </div>
            <div className="delet-user-conainer__event">
                {loading ? (
                    <RingLoader
                        color="#21AEA7"
                        loading={loading}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                ) : (
                    requestsubmit ? (
                        <div className="delet-user-conainer__event__cancel"
                            onClick={() => {
                                setRequestFail(null);
                                setRequestSuccess(null);
                                statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false)
                            }}>Close</div>
                    ) : (
                        <>
                            <div className="delet-user-conainer__event__confirm"
                                onClick={deleteUser}>
                                Confirm</div>
                            <div className="delet-user-conainer__event__cancel"
                                onClick={() => {
                                    setRequestFail(null);
                                    setRequestSuccess(null);
                                    statemanager.adminstates.adminbuttons.delete_button.delete_component_show.setState(false)
                                }}>Cancel</div>
                        </>
                    )


                )}


                {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
                {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}
            </div>



        </div>
    )
}

export default DeleteUser
