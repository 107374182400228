import React, { useContext, useEffect, useRef, useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assests/images/logo.png';
import { NavLink } from 'react-router-dom';
import '../../assests/css/style.css';
import { StateContext } from '../StateManager/StateManager';
import Dropdown from '../Dropdown/Dropdown';
import ProfileIcon from '../ProfileIcon/ProfileIcon';


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [programState, setProgramState] = useState(false);

  const statemanager = useContext(StateContext);


// closing the nav bar menu when use click outside
  const smallMenuRef = useRef(); 
  useEffect(() => {
      function handleClickOutside(event) {
          if (smallMenuRef.current && !smallMenuRef.current.contains(event.target)) {
              setToggleMenu(false);
          }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [toggleMenu]);


  return (
    <div className="neuratechlab__navbar">
      <div className="neuratechlab__navbar-links">
        <div className="neuratechlab__navbar-links_logo">
          <NavLink to="/"><img src={logo} alt="essonova neura tech lab" /></NavLink>
        </div>
        <div className="neuratechlab__navbar-links_container">
          <p className="programlist" onMouseEnter={() => setProgramState(true)} onMouseLeave={() => setProgramState(false)}>Programs</p>
          {programState && <Dropdown ondrop={setProgramState} />}
          <p><NavLink to="/about">About Us</NavLink></p>
          <p><NavLink to="/contact">Contact Us</NavLink></p>
          <p><NavLink to="/blog">Blogs</NavLink></p>
        </div>
      </div>
      {statemanager.loginstate.state ? <ProfileIcon /> :
        <div className="neuratechlab__navbar-sign">
          <p onClick={() => {
            statemanager.loginform.setState(!statemanager.loginform.state);
            statemanager.registerform.setState(false);
            statemanager.forgetpassword.setState(false);
          }}>
            Sign in</p>


          <button type="button" onClick={() => {
            statemanager.registerform.setState(!statemanager.registerform.state);
            statemanager.loginform.setState(false);
            statemanager.forgetpassword.setState(false);
          }}>
            Sign up</button>
        </div>}
      <div className="neuratechlab__navbar-menu" >
        {toggleMenu
          ? <RiCloseLine color="#3F1179" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#3F1179" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="neuratechlab__navbar-menu_container scale-up-center" ref={smallMenuRef} >
            <div className="neuratechlab__navbar-menu_container-links">
              <p className="program" onMouseEnter={() => setProgramState(true)} onMouseLeave={() => setProgramState(false)}>Programs</p>
              {programState && <Dropdown ondrop={setProgramState} />}
              <p><NavLink to="/about">About Us</NavLink></p>
              <p><NavLink to="/contact">Contact Us</NavLink></p>
              <p><NavLink to="/blog">Blogs</NavLink></p>
            </div>
            {!statemanager.loginstate.state &&
              <div className="neuratechlab__navbar-menu_container-links-sign">
                <p onClick={() => {
                  statemanager.loginform.setState(!statemanager.loginform.state);
                  statemanager.registerform.setState(false);
                  statemanager.forgetpassword.setState(false);
                  setToggleMenu(false);
                }}> Sign in</p>
                <button type="button" onClick={() => {
                  setToggleMenu(false);
                  statemanager.registerform.setState(!statemanager.registerform.state);
                  statemanager.loginform.setState(false);
                  statemanager.forgetpassword.setState(false);
                }}>
                  Sign up</button>
              </div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;