import React from 'react';
import banner from '../../assests/images/aboutus.png'

const QuoteImage = () => {
    return (
        <div className="quote-body">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="quote-image">
                <img src={banner} alt="Author" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="quote-text-container">
                <i className="fas fa-quote-left quote-icon"></i>
                <p className="quote-text">"Learn and grow in the professional Zone."</p>
                <i className="fas fa-quote-right quote-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
   

export default QuoteImage;
