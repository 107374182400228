import React, { useContext, useState } from 'react'
import { StateContext } from '../StateManager/StateManager';
import axios from 'axios';
import { RingLoader } from 'react-spinners';

const PasswordChange = () => {
    const [requestsuccess, setRequestSuccess] = useState(null);
    const [requestfail, setRequestFail] = useState(null);

    let [loading, setLoading] = useState(false); //Loader state

    let [ispasswordchanged, setIsPasswordChanged] = useState(false); // to check if pass has changed

    const [password, setPassword] = useState();
    const [confirm_password, setConfirmPassword] = useState();
    let current_user_id = "";
    const statemanager = useContext(StateContext);

    let verificationdata = "";
    let token = "";

    // checking if login in user is changing password or email verified user
    if (sessionStorage.getItem("loginData")) {
        verificationdata = JSON.parse(sessionStorage.getItem('loginData'));
        token = verificationdata.token;
        current_user_id = verificationdata.id;
    }

    else if (sessionStorage.getItem('response_emailverification')) {
        verificationdata = JSON.parse(sessionStorage.getItem('response_emailverification'));
        token = verificationdata.token;
        current_user_id = verificationdata.id;

    }


    // defining config for authorization
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    const passwordUpdate = () => {
        if (password !== confirm_password) {
            setRequestFail("Passwords do not match");
            return;
        }

        const data = {
            id: current_user_id,
            password: password
        }

        setLoading(true); //Loader State

        axios.post(`${process.env.REACT_APP_API_URL}/change-password`, data, config)
            .then((respone) => {
                setIsPasswordChanged(true);
                setLoading(false); // Loader State
                setRequestFail(null);
                setRequestSuccess("Password Changed");
                sessionStorage.removeItem("response_emailverification"); // removing the data after password is changed

            })
            .catch((error) => {
                setLoading(false); // Loader State
                setRequestSuccess(null);
                setRequestFail("Failed");
                //console.log(error);
                sessionStorage.removeItem("response_emailverification"); // removing the data after error user have to retry 
            });
    }

    return (
        <section className="user-profile-essonova">
            <div className="container-user-profile">
                <div className="section-title text-center wow fadeInDown">
                    <div className="section-tag fw-bold"></div>
                    <h3 className='gradient__text'>Password Change</h3>
                </div>
                <div className={loading ? ("user-profile-form wow fadeInUp essonova-loader") : ("user-profile-form wow fadeInUp")}>
                    {loading ? (
                        <RingLoader
                            color="#21AEA7"
                            loading={loading}
                            size={150}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    ) : (
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                {ispasswordchanged ? (
                                    <div className="col-12 d-flex">
                                        <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center">
                                            <button className="essonova-btn-rev" onClick={() => {
                                                setRequestFail(null);
                                                setRequestSuccess(null);
                                                statemanager.loginform.setState(true);
                                                statemanager.changePassword.setState(false);
                                            }}>Log In</button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <form>
                                            <div className="form-group">
                                                <input type="password" className="form-control" placeholder="Password" name="old_password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="form-control" placeholder="Confirm Password" name="new_password" value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} required />
                                                {password && confirm_password && password !== confirm_password &&
                                                    <p style={{ color: 'red' }}>Passwords do not match</p>}
                                            </div>
                                        </form>
                                        <div className="col-12 d-flex">
                                            <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center">
                                                <button disabled={password !== confirm_password} onClick={passwordUpdate} className="essonova-btn">Change</button>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center">
                                                <button className="essonova-btn-rev" onClick={() => {
                                                    setRequestFail(null);
                                                    setRequestSuccess(null);
                                                    statemanager.changePassword.setState(false)
                                                }}>Cancel</button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {requestsuccess && <p className='alert-message' style={{ padding: 10, color: 'green' }}>{requestsuccess}</p>}
                    {requestfail && <p className='alert-message' style={{ padding: 10, color: 'red' }}>{requestfail}</p>}
                </div>
            </div>
        </section>
    )
}

export default PasswordChange
