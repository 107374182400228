import React, { useContext, useState, useEffect } from 'react';
import { StateContext } from '../StateManager/StateManager';
import '../../assests/css/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { course_card_data } from '../../context/context';

const CourseSlider = () => {
    const statemanager = useContext(StateContext);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [numCardsToShow, setNumCardsToShow] = useState(3); 
    const coursecarddata = statemanager.data_from_db.coursedata.state;

    const totalCards = coursecarddata.length;

    const handleNextClick = () => {
        if (currentIndex < totalCards - numCardsToShow) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    // Function to set the number of cards to show based on screen width
    const setCardsToShow = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 1200) {
            setNumCardsToShow(3);
        } else if (screenWidth >= 750) {
            setNumCardsToShow(2);
        } else {
            setNumCardsToShow(1);
        }
    };

    // Use an effect to update the number of cards to show on window resize
    useEffect(() => {
        const handleResize = () => {
            setCardsToShow();
        };

        window.addEventListener('resize', handleResize);

        // Initialize the number of cards to show on component mount
        setCardsToShow();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const displayedCards = coursecarddata.slice(currentIndex, currentIndex + numCardsToShow);

    return (
        <section className='essonova-course-slider-container' id="bootcamps">
            <div className="section-tag fw-bold text-center">Most Awaited</div>
            <div><h3 className="gradient__text text-center">Discover our program</h3></div>
            <div><p className="course-subheading text-center">Experience a world of opportunities, growth, and knowledge through our program, where your journey to success begins.</p></div>
            <div className="essonova-slider">
                <div className="slider-icon-contianer-left" onClick={handlePrevClick}>
                    <i><FontAwesomeIcon icon={faAngleLeft} /></i>
                </div>
                <div className="slider-card-container">
                    {displayedCards.map((item, index) => {
                        return (
                            <div className="essonova-course-card" key={index}>
                                <div className="card-img">
                                    <img src={item.program_image} alt="program data" />
                                </div>
                                <div>
                                    <h5>{item.program_name}</h5>
                                    <p>{item.program_desc}</p>
                                </div>
                                <div>
                                    {/* <Link to={`/programs/${item.program_name.toLowerCase().replace(/ /g, '-')}`}> */}
                                    <Link to={course_card_data[index].path}>
                                        <button className='essonova_btn'>Explore ME!</button>
                                    </Link>
                                </div>
                            </div>

                        );
                    })}
                </div>
                <div className="slider-icon-container-right" onClick={handleNextClick}>
                    <i><FontAwesomeIcon icon={faAngleRight} /></i>
                </div>
            </div>
        </section>
    );
};

export default CourseSlider;
