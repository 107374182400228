import React from 'react'
import Image_fullstack from '../../../assests/images/programs/fstack.png'
import ParrentComponent from '../CommanComponent/ParrentComponent'
import Syllabus from '../../../components/Syllabus/Syllabus'
import Roadmap from '../../../components/Roadmap/Roadmap'
import Faq from '../../../components/Faq/Faq'
import { fullstack_bootcamp_data } from '../../../context/context'


const Fullstack = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <img className='Image_FullStack' src={Image_fullstack} alt='loding...' />
      <div className="text-center">
        <h1 className="gradient__text">Learn The Full-Stack Development </h1>
        <p>HTML, CSS, and Bootstrap, for beginners. Build your foundation with these three pillars of the Internet and ready yourself for the next training Program.</p>
      </div>
      <ParrentComponent
        roadmapcard={fullstack_bootcamp_data.roadmapcard}
        technologyDetails={fullstack_bootcamp_data.technologyDetails}
        prerequisites={fullstack_bootcamp_data.prerequisites}
      />
      <Roadmap />
      <Faq data={fullstack_bootcamp_data.fullstack_faq} />
      <Syllabus />
    </div>
  );
}

export default Fullstack
