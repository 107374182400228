import React, { useContext ,useRef,useEffect} from 'react'
import '../../assests/css/style.css';
import { StateContext } from '../StateManager/StateManager';


const ProfileMenu = () => {
    const statemanager = useContext(StateContext);
    const menuRef = useRef(); // Create a ref for the menu

    useEffect(() => {
        function handleClickOutside(event) {
            // If the user clicked outside of the menu, close it
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                statemanager.showProfileMenu.setState(false);
            }
        }
        // Add the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, 
    // eslint-disable-next-line
    []);

    return (
        <div className='profile-menu' ref={menuRef}>
            <li onClick={()=>{statemanager.displayUserProfile.setState(!statemanager.displayUserProfile.state);
            statemanager.editUserProfile.setState(false);
                statemanager.changePassword.setState(false);
                statemanager.showProfileMenu.setState(false);
            }}>My Profile</li>
            <li onClick={()=>{statemanager.editUserProfile.setState(!statemanager.editUserProfile.state);
            statemanager.displayUserProfile.setState(false);
                statemanager.changePassword.setState(false);
                statemanager.showProfileMenu.setState(false);
            }}>Edit Profile</li>
            <li 
            onClick={()=>{
                statemanager.displayUserProfile.setState(false);
                statemanager.editUserProfile.setState(false);
                    statemanager.changePassword.setState(true);
                    statemanager.showProfileMenu.setState(false);
            }}>
                Change Password</li>
            <li onClick={()=>{
                sessionStorage.clear();
                statemanager.changePassword.setState(false);
                statemanager.displayUserProfile.setState(false);
                statemanager.editUserProfile.setState(false);
                statemanager.showProfileMenu.setState(false);
                statemanager.loginstate.setState(false);
            }}>Log Out</li>
        </div>
    )
}

export default ProfileMenu
